import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonApiService } from "./../../services/common-api.service";

@Component({
  selector: "user-flow",
  templateUrl: "./addNewConsultant.component.html",
  styleUrls: ["./addNewConsultant.component.scss"],
})
export class AddNewConsultantComponent implements OnInit {
  referenceForm: FormGroup;
  personDetailsForm: FormGroup;

  constructor(private form: FormBuilder, public commonApi: CommonApiService) {}
  step = -2;
  sideModal = false;
  services_name = "services";
  functions_name = "functions";
  inductries_name = "industries";
  sideModalData = {};
  tempService = [];
  tempFunction = [];
  tempIndustry = [];
  showMessageStripe = false;

  servicesCheckboxes = [
    "Corporate Security",
    "Corporate Strategy/M&A",
    "Cybersecurity",
    "Data & Analytics",
    "Digital",
    "Diversity",
    "Executive Admin",
  ];
  functionsCheckboxes = [
    "Board of Director",
    "CEO",
    "CFO",
    "Communications",
    "Community Service",
    "Corporate Officers",
    "Corporate Security",
    "Corporate Strategy/M&A",
    "Cybersecurity",
    "Data & Analytics",
    "Digital",
    "Diversity",
    "Executive Admin",
  ];
  industriesCheckboxes = [
    "Board of Director",
    "CEO",
    "CFO",
    "Communications",
    "Community Service",
    "Corporate Officers",
    "Corporate Security",
  ];

  selectedServices = [];
  selectedFunctions = [];
  selectedIndustries = [];

  setStep(index: number) {
    this.step = index;
  }

  ngOnInit() {
    this.PersonDetailsForm();
  }

  PersonDetailsForm() {
    this.personDetailsForm = this.form.group({
      companyId: 0,
      firstName: "",
      lastName: "",
      middleName: "",
      salutation: 0,
      title: 0,
      webSite: "",
      personType: 0,
      idStatus: 0,
      esiXstatusId: 0,
      dataStatusId: 0,
      directoryStatusId: 0,
      recordStatus: 0,
      reviewedById: 0,
      bioData: "string",
      contactDetails: this.form.group({
        personId: 0,
        email: "",
        phone: "",
        ext: "",
        directPhone: "",
        fax: "",
        assistantName: "",
        asstPhone: "",
        asstExt: "",
        asstEmail: "",
        websiteURL: "",
        linkedInprofile: "",
        id: 0,
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 0,
        action: 0,
      }),
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
    });
  }

  // SAVING FORM DATA FUNCTIONS
  AddPersonDetailsForm(data) {
    this.commonApi.addConsultant(data).subscribe((res) => console.log(res));
  }

  openSideModal(type) {
    switch (type) {
      case this.services_name:
        this.sideModalData["heading"] = "Services";
        this.sideModalData["checkData"] = this.servicesCheckboxes;
        this.sideModalData["type"] = this.services_name;
        this.sideModal = true;
        break;

      case this.functions_name:
        this.sideModalData["heading"] = "Functions";
        this.sideModalData["checkData"] = this.functionsCheckboxes;
        this.sideModalData["type"] = this.functions_name;
        this.sideModal = true;
        break;

      case this.inductries_name:
        this.sideModalData["heading"] = "Industries";
        this.sideModalData["checkData"] = this.industriesCheckboxes;
        this.sideModalData["type"] = this.inductries_name;
        this.sideModal = true;
        break;
    }
  }

  removeFromList(val, type) {
    let i = null,
      j = null;
    if (!this.sideModal) {
      switch (type) {
        case this.services_name:
          i = this.selectedServices.indexOf(val);
          j = this.tempService.indexOf(val);
          this.selectedServices.splice(i, 1);
          this.tempService.splice(j, 1);
          break;

        case this.functions_name:
          i = this.selectedFunctions.indexOf(val);
          j = this.tempFunction.indexOf(val);
          this.selectedFunctions.splice(i, 1);
          this.tempFunction.splice(j, 1);
          break;

        case this.inductries_name:
          i = this.selectedIndustries.indexOf(val);
          j = this.tempIndustry.indexOf(val);
          this.selectedIndustries.splice(i, 1);
          this.tempIndustry.splice(j, 1);
          break;
      }
    }
  }

  storeCheckValue(val, type) {
    switch (type) {
      case this.services_name:
        if (this.tempService.indexOf(val) == -1) {
          this.tempService.push(val);
        } else {
          this.removeFromList(val, type);
        }
        break;

      case this.functions_name:
        if (this.tempFunction.indexOf(val) == -1) {
          this.tempFunction.push(val);
        } else {
          this.removeFromList(val, type);
        }
        break;

      case this.inductries_name:
        if (this.tempIndustry.indexOf(val) == -1) {
          this.tempIndustry.push(val);
        } else {
          this.removeFromList(val, type);
        }
        break;
    }
  }

  pushSelectedChecks(type) {
    // this.selectedFunctions.push(...this.tempArr.filter(val => this.selectedFunctions.indexOf(val) == -1))
    switch (type) {
      case this.services_name:
        this.selectedServices = [...this.tempService];
        this.sideModal = false;
        break;

      case this.functions_name:
        this.selectedFunctions = [...this.tempFunction];
        this.sideModal = false;
        break;

      case this.inductries_name:
        this.selectedIndustries = [...this.tempIndustry];
        this.sideModal = false;
        break;
    }
  }

  shouldBeCheched(val, type) {
    switch (type) {
      case this.services_name:
        return this.selectedServices.indexOf(val) !== -1;
        break;

      case this.functions_name:
        return this.selectedFunctions.indexOf(val) !== -1;
        break;

      case this.inductries_name:
        return this.selectedIndustries.indexOf(val) !== -1;
        break;
    }
  }

  getSelectedCount(type) {
    let count = 0;
    switch (type) {
      case this.services_name:
        count = this.tempService.length;
        break;

      case this.functions_name:
        count = this.tempFunction.length;
        break;

      case this.inductries_name:
        count = this.tempIndustry.length;
        break;
    }
    return count;
  }

  shouldShowAll(type) {
    switch (type) {
      case this.services_name:
        return this.tempService.length > 0;
        break;

      case this.functions_name:
        return this.tempFunction.length > 0;
        break;

      case this.inductries_name:
        return this.tempIndustry.length > 0;
        break;
    }
  }
}
