import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from './../../services/common-api.service';
import { ApiCallService } from 'src/app/services/api-call.service';

export interface PeriodicElement {
      id: number,
      createdBy: string,
      createdOn: string,
      lastUpdateBy: string,
      lastUpdateOn: string,
      isActive: boolean,
      status: number,
      action: number,
      personId: number,
      specialityTypeId: number,
      specialityId: number,
      hasInd: boolean,
      wgawInd: boolean,
      specialityName: string
}
export interface EmploymentHistory {
      id: number,
      createdBy: string,
      createdOn: string,
      lastUpdateBy: string,
      lastUpdateOn: string,
      isActive: boolean,
      status: number,
      action: number,
      personId: number,
      employerName: string,
      titleId: number,
      startDate: string,
      endDate: string,
      employmentOrder: number,
      titleName: string,
      emplDuration: string,
      crudind: number
}

let SPECIALITY_DATA: PeriodicElement[] = [];
let EMPLOYMENT_DATA: EmploymentHistory[] = [];
let CONTACT_DATA = [];




@Component({
  selector: 'app-compare-history',
  templateUrl: './compare-history.component.html',
  styleUrls: ['./compare-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CompareHistoryComponent implements OnInit {

  displayedColumns: string[] = ['specialityName', 'hasInd', 'wgawInd', 'crudInd', 'lastUpdateBy', 'lastUpdateOn'];
  displayedColumns3: string[] = ['phone', 'fax', 'email', 'linkedin', 'website', 'lastUpdatedBy', 'lastupdatedOn'];
  displayedColumns4: string[] = ['employername', 'title', 'duration', 'employmentOrder', 'lastUpdatedBy', 'lastupdatedOn'];
  

  EmploymentDetails = [];
 
  reqParams = [];
  usersData = [];
  specialityData = [];
  contactDetails = [];

  specialityDataSource = new MatTableDataSource(SPECIALITY_DATA);
  employmentDataSource = new MatTableDataSource(EMPLOYMENT_DATA);
  expandedElement: PeriodicElement | null;
  expandedElementEmp: EmploymentHistory | null;

  contactDataSource = new MatTableDataSource(CONTACT_DATA);

  userRole = localStorage.getItem("LoginRole");
  apiconfig:any;
  title:any;
  constructor(private form: FormBuilder, public commonApi: CommonApiService,private route: ActivatedRoute, private router: Router, public apiCallService: ApiCallService) {
  this.reqParams.push(this.route.snapshot.paramMap.get('history1'), this.route.snapshot.paramMap.get('history2'));
  
    this.apiconfig = this.apiCallService.apiendPoints.personDetails;
    this.route.data.subscribe(data => {
      this.title = data.title;
    });
  }

  step = 3;
  arr = new Array(5);
  sideModal = false;
  showNotes = false;
  notesModal = false;

  salutationData = [];
  personId = 0;
  recordStatusData = [];
  titleData = [];
  countryData = [];
  stateData = [];
  cityData = [];
  selectedCompany = null;
  selectedTitle = null;
  loader = true;

  // STORE ALL SPECIALITIES CHECKBOXES VALUES
  servicesCheckboxes = [];
  functionsCheckboxes = [];
  industriesCheckboxes = [];
  
  selectedServices = [];
  selectedFunctions = [];
  selectedIndustries = [];

  companyDropdownData = [];
  titleDropdownData = [];

  setStep(index: number) {
    this.step = index;
  }

  ngOnInit() {

      // this.getTitleByPerson(this.reqParams[0]);

      this.SetSalutationData();
      this.SetRecordStatusData();
      this.SetCountryData();
      this.SetStateData();
      this.SetCityData();
      this.SetServicesData();
      this.SetFunctionsData();
      this.SetIndustriesData();
      this.GetUserDetails();
      
      setTimeout(() => {
        document.querySelectorAll('input').forEach(a => a.disabled = true);
        document.querySelectorAll('select').forEach(b => b.disabled = true);
        document.querySelectorAll('textarea').forEach(c => c.disabled = true);
        
        // SET SPECIALITY TO EQUAL HEIGHT
        let height1=[], height2=[], height3=[];
        document.querySelectorAll('.sp-service').forEach(a => height1.push(a["offsetHeight"]));
        document.querySelectorAll('.sp-function').forEach(a => height2.push(a["offsetHeight"]));
        document.querySelectorAll('.sp-industry').forEach(a => height3.push(a["offsetHeight"]));
    
        document.querySelectorAll('.sp-service').forEach(a => a['style']['height'] = Math.max(...height1)+"px");
        document.querySelectorAll('.sp-function').forEach(a => a['style']['height'] = Math.max(...height2)+"px");
        document.querySelectorAll('.sp-industry').forEach(a => a['style']['height'] = Math.max(...height3)+"px");
      }, 5000)
  }



  SetSalutationData(){
    this.commonApi.getBaseData(16).subscribe((data) => {this.salutationData = data['result'];})
  }
  SetServicesData(){
    this.commonApi.getBaseData(3).subscribe((data) => {this.servicesCheckboxes = data['result']})
  }
  SetFunctionsData(){
    this.commonApi.getBaseData(2).subscribe((data) => {this.functionsCheckboxes = data['result'];})
  }
  SetIndustriesData(){
    this.commonApi.getBaseData(4).subscribe((data) => {this.industriesCheckboxes = data['result'];})
  }

  SetRecordStatusData(){
    this.commonApi.getBaseData(9).subscribe((data) => {this.recordStatusData = data['result']; if(this.userRole=='User'){this.recordStatusData = this.recordStatusData.filter(a => a.id !=1488 && a.id != 1489)}})
  }
  // SetTitleData(){
  //   this.commonApi.getBaseData(15).subscribe((data) => {this.titleData = data['result'];})
  // }

  SetCountryData(){
    this.commonApi.getBaseData(5).subscribe((data) => {this.countryData = data['result'];})
  }
  
  SetStateData(){
    this.commonApi.getBaseData(7).subscribe((data) => {this.stateData = data['result'];})
  }
  
  SetCityData(){
    this.commonApi.getBaseData(8).subscribe((data) => {this.cityData = data['result'];})
  }


  GetUserDetails(){
    for(let i=0; i<this.reqParams.length; i++){
      this.commonApi.getConsultentHistoryById(this.reqParams[i]).subscribe(res => {
        this.usersData.push(res['result']);
        this.loader = false;
        this.personId = res['result'].id || 0;
        i == 1 && (this.personId != 0 && (this.GetUserSpecialities(this.personId), this.GetContactDetails(this.personId), this.GetUserEmpHistory(this.personId)));
      })
    }
  }

  GetContactDetails(id){
    this.contactDataSource.data = [];
    this.commonApi.getContactDetails(id).subscribe(res => {
      let data = res['result'];
      const arr = this.contactDataSource.data;
      arr.push(...data);
      this.contactDataSource.data = arr;
    })
  }

  GetUserSpecialities(id){
    this.specialityDataSource.data = [];
    this.commonApi.getSpecialitiesHistory(id).subscribe(res => {
      let data = this.combindHistory(res['result'], 'specialityId');
      const arr = this.specialityDataSource.data;
      arr.push(...data);
      this.specialityDataSource.data = arr;
    })
  }

  GetUserEmpHistory(id){
    this.employmentDataSource.data = [];
    this.commonApi.getEmploymentHistory(id).subscribe(res => {
      let data = this.combindHistory(res['result'], 'id');
      const arr = this.employmentDataSource.data;
      arr.push(...data);
      this.employmentDataSource.data = arr;
    })
  }
  
  
  // setSalucation(e){
  //   this.personDetailsForm.patchValue({salutation: Number(e.target.value)})
  // }

 



  // getTitleByPerson(personId:any){
  //   this.apiCallService.getPersonDetailsById(this.apiconfig.getTitle, personId).subscribe(
  //     res => console.log(res))
  // }


  // getTitle(param){
  //     this.commonApi.searchTitle(15, key).subscribe(res => {this.titleDropdownData = res['result'], this.searchingTitle = false});
  // }

  
  getConvertedDate(date){
    let d = new Date(date);
    let tmp = d.toDateString().split(' ')
    let afterDate = tmp[2]+' '+tmp[1]+', '+tmp[3];
    let time = d.toLocaleTimeString().split(':')
    let afterTime = time[0]+":"+time[1]+" "+time[2].split(' ')[1]
    return afterDate +' '+ afterTime;
  }
  
  getRatingCount(rating, rateNum){
    let rate = rating.personReviewDetails && rating.personReviewDetails.find(a => a.reviewQnsId == rateNum);
    return rate && rate.reviewAns || 0;
  }

  shouldContact(rating){
      let rate = rating.personReviewDetails && rating.personReviewDetails.find(a => a.reviewQnsId == 4758);
      return rate && (rate.reviewAns == 1 ? 'Yes' : 'No') || ""
  }

  getSpeciality(speciality, id){
    return speciality.specialityTypeId == id ? speciality.specialityName : null;
  }
  showSpeciality(speciality, id){
    return speciality.specialityTypeId == id ? true : false;
  }
  
  // havingMoreHistory(id){
  //   return this.specialityDataSource.data.filter(a => a.specialityId == id).length > 1;
  // }

  getTruOrFalse(val){
    return val ? 1 : 0;
  }

  combindHistory(temp1, validator){
    let arr = [];
    for(let i=0; i<temp1.length; i++){
    var temp = null;
      if(arr.findIndex(a => a[validator] == temp1[i][validator]) == -1){
          temp = temp1[i];
          temp.history = temp1.filter(a => a[validator] == temp1[i][validator]).slice(1);
          arr.push(temp)
      }
    }
    return arr;
  }



}

