import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { getAPIEndpoint } from "./env";

@Injectable({
  providedIn: "root",
})
export class ApiCallService {
  // Localhost URL
  // baseUrl = 'https://localhost:44329/';

  // DEV URL
  // baseUrl = 'https://execsmart.azurewebsites.net';

  // TEST ENV URL
  baseUrl = getAPIEndpoint();

  currentconsultant: BehaviorSubject<Object> = new BehaviorSubject<Object>(
    null
  );
  selectedCountry: BehaviorSubject<Object> = new BehaviorSubject<Object>(184);
  // messageCount: BehaviorSubject<Object> = new BehaviorSubject<Object>(10)
  private messageCount = new BehaviorSubject(0);

  _setMessageCount(value) {
    // alert(value);
    this.messageCount.next(value);
  }

  getMessageCount() {
    return this.messageCount.asObservable();
  }

  consultantSearch: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    consultantName: null,
    consultantType: null,
    speciality: null,
    company: null,
    region: null,
    // hasOrAgree: 0,
    bio: null,
  });

  searchQueryPayload: BehaviorSubject<Object> = new BehaviorSubject<Object>(
    null
  );

  dashboardSpeciality: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    // specialityId: 4,
    // specialityName: 'CEO'
    specialityId: null,
    specialityName: "",
  });
  pageIndex: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);
  dashboardPagefilter: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    pageIndex: 0,
    pageSize: 10,
  });
  consultantPagefilter: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    pageIndex: 0,
    pageSize: 10,
  });
  bulkInvitePagefilter: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    pageIndex: 0,
    pageSize: 10,
  });

  sortFilter: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    sortingBy: "",
    direction: ""
  });
  sortFilterNonCon: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    sortingBy: "",
    direction: ""
  });

  sortFilterSuggestedCon: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    sortingBy: "",
    direction: ""
  });

  sortFilterReviewRatings: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    sortingBy: "",
    direction: ""
  });

  sortDefaultFilter: BehaviorSubject<Object> = new BehaviorSubject<Object>({
    sortingBy: "",
    direction: "desc"
  });

  constructor(public http: HttpClient) { }
  public apiendPoints = {
    consultant: {
      Getconsultant: "api/Consultant/Getconsultant",
      Getconsultants: "api/Consultant/Getconsultants",
      GetconsultantsBy: "api/Consultant/GetConsultantsByUser",
      AddConsultant: "api/Consultant/AddConsultant",
      consultantCount: "api/Consultant/GetConsultantCount",
      SearchConsultant: "api/Consultant/SearchConsultant",
    },
    BaseKeyData: {
      GetBaseKey: "api/base",
    },
    logIn: {
      login: `api/login/getuser`,
      loginCustom: `api/login/getuserCustom`,
      updatePassword: `api/Login/UpdatePassword`,
      ResetPassword:'api/Login/ResetPassword',
      otp:"api/Login/OTP?email=",
      signUp: `api/Login/SignUp`,
    },
    personDetails: {
      getTitle: `api/Base/GetTitleByPersonId`,
      getCompany: `api/Login/UpdatePassword`,
      getSpecialities: `api/Base/GetSpecialitiesByPersonId`,
    },
    invitation: {
      sendMail: `api/Email/SendInvitation`,
    },
    Specalities: {
      GetSpecialitiesCount: `api/Specalities/GetSpecialityCount`,
      consultantCount: `api/Consultant/GetConsultantCount`,
      reviewNewConsultantCount: `api/Consultant/GetReviewNewConsultantCount`,
      reviewdataUrl: `api/Review/GetReviewRatingsCount`,
      getConsultants: `api/Consultant/GetConsultantBySpeciality?specialityId=`,
      previewConsultant: `api/Consultant/GetConsultant`,
    },
  };
  public getPersonDetailsById(path: string, req: any) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.get(
      `${this.baseUrl}${path}?personId=${req}`,
      myInit
    );
    return retrunValue;
  }
  public getCountByCountry(path: string, req: any, user: any) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.get(
      `${this.baseUrl}${path}?countryId=${req}&userId=${user}`,
      myInit
    );
    return retrunValue;
  }
  public loginRequest(path: string, data: any) {
    let logindata = JSON.parse(JSON.stringify(data));
    let encodedUsername = encodeURIComponent(data.username);
    let encodedPassword = encodeURIComponent(data.password);
    let myInit = {
      body: data,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    let retrunValue = this.http.post(
      `${this.baseUrl}${path}?username=${encodedUsername}&password=${encodedPassword}`,
      myInit
    );
    return retrunValue;
  }
  // HTTTP METHOD POST request with queryParams
  public async postRequest(path: string, requestBody: any, queryparams: any) {
    let myInit = {
      body: requestBody,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      queryStringParameters: queryparams,
    };
    let retrunValue = this.http.post(`${this.baseUrl}${path}`, myInit);
    return retrunValue;
  }
  // HTTTP METHOD POST ************************************
  public post(path: string, req: any) {
    let myInit = {
      body: req,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.post(`${this.baseUrl}${path}`, myInit.body);
    return retrunValue;
  }
  // HTTTP METHOD PUT ************************************
  public put(path: string, req: any) {
    let myInit = {
      body: req,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.put(`${this.baseUrl}${path}/${req.id}`, myInit);
    return retrunValue;
  }
  // HTTTP METHOD PUT ************************************
  public putWithoutId(path: string, req: any) {
    let myInit = {
      body: req,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.put(`${this.baseUrl}${path}`, myInit.body);
    return retrunValue;
  }
  public getOTP(path: string, req: any) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.put(
      `${this.baseUrl}${path}${req}`,
      myInit
    );
    return retrunValue;
  }

  // HTTTP METHOD GET by Id ************************************
  public get(path: string, req: any) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.get(`${this.baseUrl}${path}${req}`, myInit);
    return retrunValue;
  }
  public getById(path: string, req: any) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.get(`${this.baseUrl}${path}/${req}`, myInit);
    return retrunValue;
  }

  public getUserById(path: string, req: any) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.post(
      `${this.baseUrl}${path}?user=${req}`,
      myInit
    );
    return retrunValue;
  }

  // HTTTP METHOD GET ************************************
  public getAll(path: string, req: any) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    let retrunValue = this.http.get(`${this.baseUrl}${path}`, myInit);
    return retrunValue;
  }
}
