import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private router: Router, private permissionsService: NgxPermissionsService) { }
  sendToken(token: string, role: string, identity: any) {
    localStorage.setItem("LoggedInUser", token)
    localStorage.setItem('execSmartUserId', identity)
    localStorage.setItem("LoginRole", role)
  }
  getToken() {
    return localStorage.getItem("LoggedInUser")
  }
  getRole() {
    return localStorage.getItem("LoginRole")
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  logout() {
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("LoginRole");
    this.router.navigate(["login"]);
    location.reload();
  }

}
