import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './home/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { DefaultComponent } from './shared/default/default.component';
import { HeaderComponent } from './shared/header/header.component';
import { SideNavComponent } from './shared/side-nav/side-nav.component';
import { SharedModule } from './shared/shared.module';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SearchInfoComponent } from './shared/search-info/search-info.component';
import { SignupComponent } from './home/signup/signup.component';
import { PopupDialouge } from './admin/review-a-consultant/review-a-consultant.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DefaultComponent,
    HeaderComponent,
    SideNavComponent,
    SignupComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  entryComponents: [
    ChangePasswordComponent,
    ForgotPasswordComponent,
    SearchInfoComponent,
    PopupDialouge
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
