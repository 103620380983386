import { Component, OnInit } from "@angular/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommonApiService } from "src/app/services/common-api.service";
import { AppSettingsService } from "src/app/services/app.settings.service";
import { ApiCallService } from "src/app/services/api-call.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  userData: any;
  emailPattern = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  hide: boolean = true;
  email: string;
  pass: string;
  BasicFormGroup: any;
  endPointConfig: any;
  loader = false;
  errorMsg = "";
  reqParamsId: any;
  apiConfig: any;
  getConsultantDatails: any;
  securityQuestions: any;
  submitted = false;
  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private auth: AuthService,
    public commonApi: CommonApiService,
    public apiEndPoint: AppSettingsService,
    public apiCallService: ApiCallService
  ) {
    this.endPointConfig = this.apiCallService.apiendPoints.logIn.signUp;
    this.apiConfig = this.apiCallService.apiendPoints.Specalities;
    this.reqParamsId = this.route.snapshot.paramMap.get("personId");
  }
  ngOnInit() {
    this.createLogin();
    if (this.reqParamsId != "") {
      this.getConsultant(this.reqParamsId);
    }
    this.getSecurityQuestions();
  }
  getConsultant(reqParamsId) {
    this.apiCallService
      .getById(this.apiConfig.previewConsultant, reqParamsId)
      .subscribe(
        (response) => {
          this.getConsultantDatails = response["result"];
          this.patchEditConsultant(this.getConsultantDatails);
        },
        (err) => {
          console.log("Demo", err);
        }
      );
  }
  patchEditConsultant(item: any) {
    this.BasicFormGroup.controls["firstName"].setValue(item.firstName);
    this.BasicFormGroup.controls["middleName"].setValue(item.middleName);
    this.BasicFormGroup.controls["lastName"].setValue(item.lastName);
    this.BasicFormGroup.controls["email"].setValue(
      item.contactDetails ? item.contactDetails.email : ""
    );
  }
  getSecurityQuestions() {
    this.commonApi.getBaseData(21).subscribe((data) => {
      this.securityQuestions = data["result"];
    });
  }
  createLogin() {
    this.BasicFormGroup = this.fb.group(
      {
        firstName: [{ value: "", disabled: true }, Validators.required],
        middleName: [{ value: "", disabled: true }, Validators.required],
        lastName: [{ value: "", disabled: true }, Validators.required],
        email: [{ value: "", disabled: true }, Validators.required],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
        securityQuestion: ["", Validators.required],
        securityAns: ["", Validators.required],
      },
      { validator: this.MustMatch("password", "confirmPassword") }
    );
  }
  get f() {
    return this.BasicFormGroup.controls;
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  onFormSubmit(form: FormGroup) {
    this.submitted = true;
    if (form.valid) {
      let object = form.value;
      let data = {
        id: Number(this.reqParamsId),
        createdBy: new Date().toISOString(),
        createdOn: new Date().toISOString(),
        lastUpdateBy: this.reqParamsId,
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 0,
        action: 0,
        username: "",
        password: object.password,
        oldPassword: "",
        securityQnsId: object.securityQuestion,
        secutiryAns: object.securityAns,
      };
      this.loader = true;
      this.apiCallService.putWithoutId(this.endPointConfig, data).subscribe(
        (response) => {
          let resData = response["result"];
          if (resData) {
            this.loader = false;
            this._snackBar.open(
              "Signed up successfully, Please login to continue",
              "GOT IT",
              {
                duration: 4000,
              }
            );
            setTimeout(() => {
              this.router.navigate([`login`]);
            }, 3000);
          } else {
            this.loader = false;
          }
        },
        (err) => {
          console.log("Demo", err);
        }
      );
    } else {
      this.loader = false;
      this.errorMsg = "Username and password can not be empty.";
    }
  }
}
