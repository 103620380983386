import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

export interface PeriodicElement {
  company: string;
  consultantName: string;
  phoneNo: number;
  speciality: string;
  country: string;
  status: string;
  actions: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },

];

@Component({
  selector: 'user-flow',
  templateUrl: './suggest-a-consultant.component.html',
  styleUrls: ['./suggest-a-consultant.component.scss']
})
export class SuggestAConsultantComponent implements OnInit {
  displayedColumns: string[] = ['consultantName', 'company', 'phoneNo', 'speciality', 'country', 'status', 'actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  advanceSearchShow:boolean = false;

  constructor() { }

  ngOnInit() {
    console.log('dataSource', this.dataSource.filteredData.length)
    this.dataSource.sort = this.sort;

  }
  toggleAdvanceSearch() {
    this.advanceSearchShow = !this.advanceSearchShow;
  }
  clearFilter() {
    // reset filter Selection...
  }

}




