import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AppSettingsService } from './app.settings.service';


@Injectable({
  providedIn: 'root'
})

export class CommonApiService implements OnInit {

  public getBaseValuesUrl;
  public addBaseValueUrl;
  public getBaseKeysUrl;
  public getConsultantUrl;
  public getConsultantsUrl;
  public addConsultantUrl;
  public getCompanyUrl;
  public searchConsultantUrl;
  public addCompanyUrl;
  public updateCompanyUrl;
  public getTitleUrl;
  public addSpecialitiesUrl;
  public getSpecialitiesUrl;
  public updateSpecialitiesUrl;
  public updateConsultantUrl;
  public saveBiographyUrl;
  public saveEmploymentDetailsUrl;
  public getEmploymentDetailsUrl;
  public getReferencesUrl;
  public saveReferencesUrl;
  public getConsultentDetailsUrl;
  public addNotesUrl;
  public getNotesUrl;
  public uploadBulkUsersUrl;
  public ​saveBulkUsersUrl;
  public getNoteUrl;

  public addReviewUrl;
  public getReviewUrl;
  public getReviewByPersonUrl;
  public updateReviewUrl;

  public ShowConsultantHistoryUrl;
  public GetConsultantHistoryByIdUrl;
  public GetSpecialitiesHistoryUrl;
  public GetContactDetailsUrl;
  public GetEmploymentHistoryUrl;
  public UpdateBaseKeyValueUrl;
  public DeleteBaseKeyValueUrl;

  public GetUserMessagesUrl;
  public AddUserMessageUrl;
  public UpdateUserMessageUrl;
  public GetUnReadMessageCountUrl;
  public SearchPersonUrl;
  public LogOffUrl;
  public SendInvitationUrl

  public SearchUserActivityUrl;
  public SubmitConsultantUrl;
  public GetCompaniesWithIDsURL;

  public GetAllPermissionsURL;
  public GetPermissionsByPersonidURL;
  public UpdatePermissionsURL;
  public ValidatePersonEmailURL;
  public GetNonConsultantsURL;
  public GetRecentSearchByPersonIdURL;
  public DeleteCompanyURL;
  public DeleteCompanyAddressURL;

  constructor(public http: HttpClient, public appSettings: AppSettingsService) {
    this.getBaseValuesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.BaseKeyData.GetBaseValues;
    this.UpdateBaseKeyValueUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.BaseKeyData.UpdateBaseKeyValue;
    this.DeleteBaseKeyValueUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.BaseKeyData.DeleteBaseKeyValue;
    this.addNotesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Notes.SaveNotes;
    this.getNotesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Notes.GetNotes;
    this.getNoteUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Notes.GetNote;
    

    this.addBaseValueUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.BaseKeyData.AddBaseKeyValue;
    this.getBaseKeysUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.BaseKeyData.GetBaseKeys;
    this.getConsultantUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.Getconsultant;
    this.getConsultantsUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.Getconsultants;
    this.addConsultantUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.AddConsultant;
    this.getCompanyUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.CompanyData.GetCompany;
    this.searchConsultantUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.SearchConsultant;
    this.addCompanyUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.CompanyData.AddCompany;
    this.updateCompanyUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.CompanyData.UpdateCompany;
    this.DeleteCompanyURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.CompanyData.DeleteCompany;
    this.DeleteCompanyAddressURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.CompanyData.DeleteCompanyAddress;

    this.GetNonConsultantsURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.GetNonConsultants;

    this.getTitleUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.GetTitle;
    this.GetRecentSearchByPersonIdURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.GetRecentSearchByPersonId;
    this.addSpecialitiesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Specialities.AddSpecialities;
    this.updateConsultantUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.UpdateConsultant;
    this.getSpecialitiesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Specialities.GetSpecialities;
    this.updateSpecialitiesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Specialities.UpdateSpecialities;
    this.saveBiographyUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Biography.SaveBiography;
    this.saveEmploymentDetailsUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Employment.SaveEmploymentDetails;
    this.getEmploymentDetailsUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Employment.GetEmploymentDetails;
    this.getConsultentDetailsUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.GetConsultantDetails;
    this.uploadBulkUsersUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.UploadBulkUsers;
    this.saveBulkUsersUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.​SaveBulkUsers;

    this.getReferencesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Reference.GetReferences;
    this.saveReferencesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Reference.SaveReferences;

    this.addReviewUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Reviews.AddReview;
    this.getReviewUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Reviews.GetReview;
    this.getReviewByPersonUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Reviews.GetReviewByPerson;
    this.updateReviewUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Reviews.UpdateReview;

    this.ShowConsultantHistoryUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.History.ShowConsultantHistory;
    this.GetConsultantHistoryByIdUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.History.GetConsultantHistoryById;
    this.GetSpecialitiesHistoryUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.History.GetSpecialitiesHistory;
    this.GetContactDetailsUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.History.GetContactDetails;
    this.GetEmploymentHistoryUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.History.GetEmploymentHistory;

    this.GetUserMessagesUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Messages.GetUserMessages;
    this.AddUserMessageUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Messages.AddUserMessage;
    this.UpdateUserMessageUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Messages.UpdateUserMessage;
    this.GetUnReadMessageCountUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Messages.GetUnReadMessageCount;
    this.SearchPersonUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Messages.SearchPerson;
    this.LogOffUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.logIn.LogOff;

    this.SearchUserActivityUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.UserActivity.SearchUserActivities;
    this.SendInvitationUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Invitation.SendInvitation;
    this.SubmitConsultantUrl = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.AdminData.SubmitConsultant;
    this.GetCompaniesWithIDsURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.CompanyData.GetCompaniesWithIDs;

    this.GetAllPermissionsURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Permissions.GetAllPermissions;
    this.GetPermissionsByPersonidURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Permissions.GetPermissionsByPersonID;
    this.UpdatePermissionsURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Permissions.UpdatePermissions;

    this.ValidatePersonEmailURL = appSettings.consultantApiBaseUrl + appSettings.ext.CoreData.Invitation.ValidatePersonEmail;
  }

  ngOnInit() {
  }

  static HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json'
      })
    };
  }


  getBaseData(id) {
    let cUrl = `${this.getBaseValuesUrl}?baseKeyId=${id}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  validateEmail(email) {
    let cUrl = `${this.ValidatePersonEmailURL}?emailToValidate=${email}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getAllPermissions() {
    let cUrl = `${this.GetAllPermissionsURL}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getPermissionsByPersonId(personId) {
    let cUrl = `${this.GetPermissionsByPersonidURL}?personID=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getBaseKeys() {
    let cUrl = `${this.getBaseKeysUrl}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getCompanyData(name) {
    let cUrl = `${this.getCompanyUrl}?name=${name}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getCompaniesbyid(name) {
    let cUrl = `${this.GetCompaniesWithIDsURL}?name=${name}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  searchConsultants(specialityId, countryId, name, company) {
    let cUrl = `${this.searchConsultantUrl}/${specialityId}/${countryId}/${name}/${company}`;
    return this.http.post(cUrl, CommonApiService.HttpOptions())
  }
  searchTitle(basekeyId, param) {
    let cUrl = `${this.getTitleUrl}/?baseKeyId=${basekeyId}&name=${param}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }
  getSpecialities(personId) {
    let cUrl = `${this.getSpecialitiesUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }
  getEmploymentDetails(personId) {
    let cUrl = `${this.getEmploymentDetailsUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getReviewByPerson(personId, userId) {
    let cUrl = `${this.getReviewByPersonUrl}?personId=${personId}&userId=${userId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getReferences(personId) {
    let cUrl = `${this.getReferencesUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getConsultentDetails(personId) {
    let cUrl = `${this.getConsultentDetailsUrl}?id=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getConsultentHistory(personId) {
    let cUrl = `${this.ShowConsultantHistoryUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }
  getConsultentHistoryById(historyId) {
    let cUrl = `${this.GetConsultantHistoryByIdUrl}?historyId=${historyId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }
  getSpecialitiesHistory(personId) {
    let cUrl = `${this.GetSpecialitiesHistoryUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }
  getEmploymentHistory(personId) {
    let cUrl = `${this.GetEmploymentHistoryUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }
  getContactDetails(personId) {
    let cUrl = `${this.GetContactDetailsUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getNotes(personId) {
    let cUrl = `${this.getNotesUrl}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }
  getNote(id) {
    let cUrl = `${this.getNoteUrl}?id=${id}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getReview(reviewId, userId) {
    let cUrl = `${this.getReviewUrl}?reviewId=${reviewId}&userId=${userId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getUserMessages(userId) {
    let cUrl = `${this.GetUserMessagesUrl}?userId=${userId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getUnreadMessageCount(userId) {
    let cUrl = `${this.GetUnReadMessageCountUrl}?userId=${userId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  searchPerson(name) {
    let cUrl = `${this.SearchPersonUrl}?name=${name}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  GetNonConsultants(data) {
    let cUrl = `${this.GetNonConsultantsURL}`;
    return this.http.post(cUrl, data)
  }

  GetRecentSearches(personId) {
    let cUrl = `${this.GetRecentSearchByPersonIdURL}?personId=${personId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }




  updateSpecialities(data) {
    let cUrl = this.updateConsultantUrl
    return this.http.put(cUrl, data)
  }
  updatebaseKey(data) {
    let cUrl = this.UpdateBaseKeyValueUrl
    return this.http.put(cUrl, data)
  }
  deleteBaseKey(data) {
    let cUrl = this.DeleteBaseKeyValueUrl
    return this.http.put(cUrl, data)
  }
  updateMessage(data) {
    let cUrl = this.UpdateUserMessageUrl
    return this.http.put(cUrl, data)
  }

  updateCompany(data) {
    let cUrl = this.updateCompanyUrl;
    return this.http.put(cUrl, data)
  }

  deleteCompany(companyId) {
    let cUrl = `${this.DeleteCompanyURL}?companyId=${companyId}`;
    return this.http.put(cUrl, CommonApiService.HttpOptions())
  }

  deleteCompanyAddress(companyAddressId) {
    let cUrl = `${this.DeleteCompanyAddressURL}?companyAddressId=${companyAddressId}`;
    return this.http.put(cUrl, CommonApiService.HttpOptions())
  }



  addCompany(data) {
    let cUrl = this.addCompanyUrl;
    return this.http.post(cUrl, data)
  }

  searchUserActivity(data) {
    let cUrl = this.SearchUserActivityUrl;
    return this.http.post(cUrl, data)
  }
  addSpecialities(data) {
    let cUrl = this.addSpecialitiesUrl;
    return this.http.post(cUrl, data)
  }
  addBiography(data) {
    let cUrl = this.saveBiographyUrl;
    return this.http.post(cUrl, data)
  }
  addEmploymentDetails(data) {
    let cUrl = this.saveEmploymentDetailsUrl;
    return this.http.post(cUrl, data)
  }
  addReferences(data) {
    let cUrl = this.saveReferencesUrl;
    return this.http.post(cUrl, data)
  }
  addReview(data) {
    let cUrl = this.addReviewUrl;
    return this.http.post(cUrl, data)
  }

  addBaseValue(data) {
    let cUrl = this.addBaseValueUrl;
    return this.http.post(cUrl, data)
  }

  addMessage(data) {
    let cUrl = this.AddUserMessageUrl;
    return this.http.post(cUrl, data)
  }

  addNotes(data) {
    let cUrl = this.addNotesUrl;
    return this.http.post(cUrl, data)
  }

  updatePermission(data) {
    let cUrl = this.UpdatePermissionsURL;
    return this.http.post(cUrl, data)
  }

  logOff(personId) {
    let cUrl = `${this.LogOffUrl}?personId=${personId}`;
    return this.http.post(cUrl, CommonApiService.HttpOptions())
  }

  sendBulkInvitation(data) {
    let cUrl = `${this.SendInvitationUrl}`;
    return this.http.post(cUrl, data)
  }

  submitConsultantData(data) {
    let cUrl = `${this.SubmitConsultantUrl}`;
    return this.http.post(cUrl, data)
  }


  // HTTTP METHOD POST request with queryParams
  public async postRequest(path: string, requestBody: any, queryparams: any) {
    let myInit = {
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      queryStringParameters: queryparams
    };
    let retrunValue = this.http.post(path, myInit);
    return retrunValue
  }


  // HTTTP METHOD POST ************************************
  public async post(path: string, req: any) {
    let myInit = {
      body: req,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    };
    let retrunValue = this.http.post(path, myInit);
    return retrunValue
  }
  // HTTTP METHOD PUT ************************************
  public async put(path: string, req: any) {
    let myInit = {
      body: req,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    };
    let retrunValue = this.http.put(`${path}/${req.id}`, myInit);
    return retrunValue;
  }
  // HTTTP METHOD GET by Id ************************************
  public async get(path: string, req: any) {
    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    };
    let retrunValue = this.http.get(`${path}/${req}`, myInit);
    return retrunValue;
  }
  // HTTTP METHOD GET ************************************
  public async getAll(path: string, req: any) {
    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    };

    let retrunValue = this.http.get(path, myInit);
    return retrunValue
  }

  getConsultant(id, context = 0, userId) {
    let cUrl = `${this.getConsultantUrl}/${id}?pageContext=${context}&userId=${userId}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  getConsultants(pageIndex, limit) {
    let cUrl = `${this.getConsultantsUrl}?pageIndex=${pageIndex}&pageSize=${limit}`;
    return this.http.get(cUrl, CommonApiService.HttpOptions())
  }

  addConsultant(data) {
    let cUrl = this.addConsultantUrl;
    return this.http.post(cUrl, data)
  }
  updateConsultant(data) {
    let cUrl = this.updateConsultantUrl;
    return this.http.put(cUrl, data)
  }

  updateReview(data) {
    let cUrl = this.updateReviewUrl;
    return this.http.put(cUrl, data)
  }

  uploadBulkUsers(file){
    let cUrl = `${this.uploadBulkUsersUrl}`;
    return this.http.post(cUrl,file)
  }

  saveBulkUsers(data){
    let cUrl = this.saveBulkUsersUrl;
    return this.http.post(cUrl,data)
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      //console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
