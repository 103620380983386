import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { CommonApiService } from './../../services/common-api.service';
import { ApiCallService } from './../../services/api-call.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-add-messages',
  templateUrl: './add-messages.component.html',
  styleUrls: ['./add-messages.component.scss'],
})
export class AddMessageComponent implements OnInit {
  messageForm: FormGroup;
  loader = false;
  data = null;
  type = 'reply';
  selectedUser = null;
  userData = [];
  searching = false;
  timer = null;
  messageCount = 0;


  constructor(private _snackBar: MatSnackBar, private apiCallService: ApiCallService, private form: FormBuilder, public commonApi: CommonApiService,private route: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    this.data = history.state.data;
    this.type = history.state.type ? history.state.type : 'reply';
    this.MessageForm();
    if(this.data){
        this.updateMessage(this.data);
        this.getMessageCount(localStorage.getItem("LoggedInUser"));
        this.fetchStatus();
    }
  }

  getMessageCount(id){
    this.commonApi.getUnreadMessageCount(id).subscribe(res => {
      this.messageCount = res['result'];
      this.apiCallService._setMessageCount(Number(res['result']))
      this.fetchStatus();
    })
  }

  fetchStatus =()=>{
    this.apiCallService.getMessageCount().subscribe(res=>{
      this.messageCount = res;
    });
  }



  MessageForm(){
    this.messageForm = this.form.group({
      "id": 0,
      "createdBy": localStorage.getItem("LoggedInUser"),
      "createdOn": new Date().toISOString(),
      "lastUpdateBy": localStorage.getItem("LoggedInUser"),
      "lastUpdateOn": new Date().toISOString(),
      "isActive": true,
      "status": 0,
      "action": 0,
      "parentUserMessageId": 0,
      "fromPersonId": 0,
      "toPersonId": 0,
      "messageData": "",
      "readInd": false,
      "deletInd": 0,
      "messageBy": ""
    })
  }


  SubmitMessage(e){
    e.preventDefault();
    if(this.messageForm.value.messageData == ''){
        this._snackBar.open('Please type a message', 'GOT IT', {
          duration: 4000,
        });
    }else if(this.data == null && this.selectedUser == null){
      this._snackBar.open('Please select a user to send message', 'GOT IT', {
        duration: 4000,
      });
    }else{
      this.loader = true;
      this.messageForm.patchValue({
        parentUserMessageId: this.data ? this.data.id : 0,
        fromPersonId: Number(localStorage.getItem("LoggedInUser")),
        toPersonId: this.data ? this.data.fromPersonId : this.selectedUser.personId
      })
      this.commonApi.addMessage(this.messageForm.value).subscribe(res => {
        this._snackBar.open('Your message has been sent successfully', 'GOT IT', {
          duration: 4000,
        });
        this.messageForm.disable();
        this.loader = false;
      })      
    }
  }

  updateMessage(data){
    this.loader = true;
    data.readInd = true;
    data.lastUpdateOn = new Date().toISOString()
    data.lastUpdateBy= localStorage.getItem("LoggedInUser")

    this.commonApi.updateMessage(data).subscribe(res => {
      console.log(res);
      this.loader = false;
    })
  }

  gotoMessage(role){
    if(role == 'Admin'){
      this.router.navigate([`admin/messages`]);
    }
    if(role == 'User'){
      this.router.navigate([`user/messages`]);
    }
  }

  getConvertedDate(date){
    let d = new Date(date);
    let tmp = d.toDateString().split(' ')
    let afterDate = tmp[2] + ' ' + tmp[1] + ', ' + tmp[3];
    let time = d.toLocaleTimeString().split(':')
    let afterTime = time[0] + ":" + time[1] + " " + time[2].split(' ')[1]
    return afterDate + ' ' + afterTime;
  }


  shouldBeDisabled(){
    return this.data ? (this.data.id > 0 ? true : false) : false;
  }

  getUserData(event){
    let key = event.target.value;
    this.searching = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.commonApi.searchPerson(key).subscribe(res => {this.userData = res['result'], this.searching = false});
    }, 600)
  }
  selectUser(data){
    this.selectedUser = data;
    this.userData = [];
  }


  getData(type){
    switch(type){
      case 'user': 
          return this.data ? (this.data.messageBy ? this.data.messageBy.split('null').join('') : "") : "";
      case 'msg':
          return this.data ? (this.data.messageData ? this.data.messageData : "") : "";
    }
  }

}

