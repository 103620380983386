import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '../admin/dashboard/dashboard.component';
import { ConsultantComponent } from '../user/Consultant/consultant.component';
import { ReviewRatingsComponent } from '../admin/review-ratings/review-ratings.component';
import { AddNewConsultantComponent } from '../user/AddConsultant/addNewConsultant.component';
import { ReviewRatingsViewComponent } from '../admin/review-ratings-view/review-ratings-view.component';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { SuggestAConsultantComponent } from '../user/SuggestConsultant/suggest-a-consultant.component';
import { ReviewNewConsultantComponent } from '../admin/review-new-consultant/review-new-consultant.component';
import { ReviewAConsultantComponent } from './../admin/review-a-consultant/review-a-consultant.component';
import{ CompareConsultantComponent } from './../admin/compare-consultant/compare-consultant.component';
import{ CompareHistoryComponent } from './../admin/compare-history/compare-history.component';
import { ReactiveFormsModule , FormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SelectUserDialog } from '../admin/review-new-consultant/review-new-consultant.component';
import { PopupDialouge } from './../admin/review-a-consultant/review-a-consultant.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConsultantViewComponent } from '../admin/consultant-view/consultant-view.component';
import { SearchInfoComponent } from './search-info/search-info.component';
import { BaseKeyComponent } from './../admin/basekey/basekey.component';
import { MessageComponent } from './../admin/messages/messages.component';
import { AddMessageComponent } from './../admin/add-messages/add-messages.component';
import { UserActivityComponent } from './../admin/user-activity/user-activity.component';
import { CompanyComponent } from './../admin/company/company.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { BulkUploadComponent } from '../admin/bulk-upload/bulk-upload.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ConsultantComponent,
    ReviewRatingsComponent,
    AddNewConsultantComponent,
    ReviewRatingsViewComponent,
    ReviewAConsultantComponent,
    CompareConsultantComponent,
    CompareHistoryComponent,
    BaseKeyComponent,
    CompanyComponent,
    MessageComponent,
    AddMessageComponent,
    UserActivityComponent,
    SuggestAConsultantComponent,
    ReviewNewConsultantComponent,
    ChangePasswordComponent,
    SelectUserDialog,
    PopupDialouge,
    ConsultantViewComponent,
    SearchInfoComponent,
    BulkUploadComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableExporterModule,
    NgxPermissionsModule.forChild(),
  ],
  exports:[
    DashboardComponent,
    ConsultantComponent,
    ReviewRatingsComponent,
    AddNewConsultantComponent,
    ReviewRatingsViewComponent,
    SuggestAConsultantComponent,
    ReviewNewConsultantComponent,
    ChangePasswordComponent,
    SelectUserDialog,
    PopupDialouge,
    ConsultantViewComponent,
    ForgotPasswordComponent
  ]
})
export class SharedModule { }
