import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonApiService } from "./../../services/common-api.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";

let USER_DATA = [];

@Component({
  selector: "app-user-activity",
  templateUrl: "./user-activity.component.html",
  styleUrls: ["./user-activity.component.scss"],
})
export class UserActivityComponent implements OnInit {
  userActivityForm: FormGroup;

  messageDataSource = new MatTableDataSource(USER_DATA);
  loader = true;
  activityData = [];
  selectedUser = null;
  searching = false;
  userData = [];
  timer = null;

  constructor(
    private _snackBar: MatSnackBar,
    private form: FormBuilder,
    public commonApi: CommonApiService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  displayedColumns: string[] = ["activity", "activityDetails", "date", "by"];
  ngOnInit() {
    this.GetActivityData();
    this.UserActivityForm();
  }

  GetActivityData() {
    this.commonApi.getBaseData(22).subscribe((data) => {
      this.activityData = data["result"];
      this.loader = false;
    });
  }

  UserActivityForm() {
    this.userActivityForm = this.form.group({
      specialityId: [],
      companyId: 0,
      countryId: 0,
      name: "",
      consultantTypeId: 0,
      regionId: 0,
      userId: 0,
      role: 0,
      gridType: 0,
      pageIndex: 0,
      pageSize: 50,
      activityTypeId: 0,
      startDate: this.getPreviousDate(),
      endDate: new Date().toISOString(),
    });
  }

  getPreviousDate() {
    var y = new Date();
    y.setDate(y.getDate() - 1);
    return y.toISOString();
  }

  getActivityType(event) {
    this.userActivityForm.patchValue({
      activityTypeId: event.value,
    });
  }

  AddUserActivityForm(event) {
    event.preventDefault();
    this.loader = true;
    this.userActivityForm.patchValue({
      startDate: new Date(this.userActivityForm.value.startDate).toISOString(),
      endDate: new Date(this.userActivityForm.value.endDate).toISOString(),
    });
    // console.log(this.userActivityForm.value)
    this.commonApi
      .searchUserActivity(this.userActivityForm.value)
      .subscribe((res) => {
        this.messageDataSource.data = [];
        let data = res["result"];
        const arr = this.messageDataSource.data;
        arr.push(...data);
        if (arr.length == 0) {
          this._snackBar.open("No data found for given search", "GOT IT", {
            duration: 4000,
          });
        }
        this.messageDataSource.data = arr;
        this.loader = false;
      });
  }

  getUserData(event) {
    let key = event.target.value;
    if (key === '') {
      this.userData = [];
      return;
    }
    this.searching = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.commonApi.searchPerson(key).subscribe((res) => {
        (this.userData = res["result"]), (this.searching = false);
      });
    }, 600);
  }
  selectUser(data) {
    this.selectedUser = data;
    this.userActivityForm.patchValue({ userId: data.personId });
    this.userData = [];
  }

  getActivity(id) {
    return this.activityData.find((a) => a.id == id).baseKeyValueName || "";
  }

  shouldBeDisabled() {
    return this.messageDataSource.data.length <= 0 ? true : false;
  }

  ExecuteSearch(searchData) {
    this.router.navigate(["/admin/consultants/0"], { state: { searchData } });
  }

  getConvertedDate(date) {
    let d = new Date(date);
    let tmp = d.toDateString().split(" ");
    let afterDate = tmp[2] + " " + tmp[1] + ", " + tmp[3];
    let time = d.toLocaleTimeString().split(":");
    let afterTime = time[0] + ":" + time[1] + " " + time[2].split(" ")[1];
    return afterDate + " " + afterTime;
  }
}
