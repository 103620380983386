import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  casemgtNavigationGuide = new Subject<string>();
  private subject = new Subject<any>();
  constructor(private _snackBar: MatSnackBar) {}
  sortByName(arr: any) {
    return arr.sort((a, b) => a.firstName.localeCompare(b.firstName));
  }
  sortByRating(arr: any){
    return arr.sort((a, b)=> (b.rating||0) - (a.rating||0));
  }
  sendclickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  showToast(message) {
    this._snackBar.open(message, "GOT IT", { duration: 4000 });
  }
}
