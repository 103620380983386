import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonApiService } from './../../services/common-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCallService } from 'src/app/services/api-call.service';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';

let REVIEWS_DATA = [];

@Component({
  selector: 'app-basekey',
  templateUrl: './basekey.component.html',
  styleUrls: ['./basekey.component.scss'],
})
export class BaseKeyComponent implements OnInit {

  addBaseValueForm: FormGroup;
  reviewsDataSource = new MatTableDataSource(REVIEWS_DATA);
  baseKeyData = [];
  sideModalOpen = false;
  loader = true;
  selectedMaster = null;
  selectedText = "";
  editMode = false;
  sortedData: any = [];
  tempArr = [];

  constructor(
    private _snackBar: MatSnackBar,
    private form: FormBuilder,
    public commonApi: CommonApiService,
    private route: ActivatedRoute,
    private router: Router,
    public apiCallService: ApiCallService
  ) {
    this.sortedData = this.reviewsDataSource.data.slice();
  }

  displayedColumns4: string[] = ['mastercodevalue', 'mastercodedesc', 'activeindecator', 'lastdate', 'modifiedby', 'action'];
  ngOnInit() {
    this.getBaseKeys();
    this.AddBaseValueForm();
  }

  getBaseKeys() {
    this.commonApi.getBaseKeys().subscribe(res => {
      this.baseKeyData = res['result'].sort((a, b) => a.baseKeyName.localeCompare(b.baseKeyName));
      this.loader = false;
    })
  }



  AddBaseValueForm() {
    this.addBaseValueForm = this.form.group({
      "id": 0,
      "createdBy": localStorage.getItem("LoggedInUser"),
      "createdOn": new Date().toISOString(),
      "lastUpdateBy": localStorage.getItem("LoggedInUser"),
      "lastUpdateOn": new Date().toISOString(),
      "isActive": true,
      "status": 0,
      "action": 0,
      "baseKeyId": 0,
      "baseKeyValueName": "",
      "baseKeyValueDesc": "",
      "baseKeyValueJson": ""
    })
  }

  editMasterCode(val) {
    this.addBaseValueForm.patchValue({
      "id": val.id,
      "createdBy": val.createdBy,
      "createdOn": val.createdOn,
      "lastUpdateBy": localStorage.getItem("LoggedInUser"),
      "lastUpdateOn": new Date().toISOString(),
      "isActive": val.isActive,
      "status": val.status,
      "action": val.action,
      "baseKeyId": val.baseKeyId,
      "baseKeyValueName": val.baseKeyValueName,
      "baseKeyValueDesc": val.baseKeyValueDesc,
      "baseKeyValueJson": val.baseKeyValueJson
    })
    this.editMode = true;
    this.sideModalOpen = true;
  }

  SubmitBaseValueForm() {
    this.loader = true;
    if (this.editMode) {
      this.commonApi.updatebaseKey(this.addBaseValueForm.value).subscribe(res => {
        this._snackBar.open(this.selectedText + ' updated successfully', 'GOT IT', {
          duration: 4000,
        });
        this.resetMastercodeForm();

        this.sideModalOpen = false;
        this.SetTableData(this.selectedMaster);
      })
    }
    else {
      this.commonApi.addBaseValue(this.addBaseValueForm.value).subscribe(res => {
        this._snackBar.open(this.selectedText + ' added successfully', 'GOT IT', {
          duration: 4000,
        });
        this.resetMastercodeForm();
        this.sideModalOpen = false;
        this.SetTableData(this.selectedMaster);

      })
    }
  }

  resetMastercodeForm() {
    this.addBaseValueForm.patchValue({
      "id": 0,
      "createdBy": localStorage.getItem("LoggedInUser"),
      "createdOn": new Date().toISOString(),
      "lastUpdateBy": localStorage.getItem("LoggedInUser"),
      "lastUpdateOn": new Date().toISOString(),
      "isActive": true,
      "status": 0,
      "action": 0,
      "baseKeyValueName": "",
      "baseKeyValueDesc": "",
      "baseKeyValueJson": ""
    })
  }

  selectVal(event) {
    if (event.value) {
      this.loader = true;
      this.selectedMaster = event.value;
      this.selectedText = this.baseKeyData.find(a => a.id == event.value).baseKeyName;

      this.addBaseValueForm.patchValue({ baseKeyId: Number(event.value) })
      this.commonApi.getBaseData(event.value).subscribe((data) => {
        // const arr = this.reviewsDataSource.data;
        // arr.push(...data['result']);
        // this.reviewsDataSource.data = arr;
        this.SetTableData(this.selectedMaster)
        this.loader = false;
      })
    }
  }



  getConvertedDate(date) {
    let d = new Date(date || new Date());
    let tmp = d.toDateString().split(' ')
    let afterDate = tmp[2] + ' ' + tmp[1] + ', ' + tmp[3];
    let time = d.toLocaleTimeString().split(':')
    let afterTime = time[0] + ":" + time[1] //+" "+time[2].split(' ')[1]
    return afterDate + ' ' + afterTime;
  }

  sortData(sort: Sort) {
    const data = this.reviewsDataSource.filteredData.slice();
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    } else {
      this.sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        switch (sort.active) {
          case "mastercodevalue":
            return compare(a.baseKeyValueName, b.baseKeyValueName, isAsc);
          case "mastercodedesc":
            return compare(a.baseKeyValueDesc, b.baseKeyValueDesc, isAsc);
          case "activeindecator":
            return compare(a.isActive, b.isActive, isAsc);
          case "lastdate":
            return compare(a.lastUpdateOn, b.lastUpdateOn, isAsc);
          case "modifiedby":
            return compare(a.lastUpdateBy, b.lastUpdateBy, isAsc);
          default:
            return 0;
        }
      });
    }
  }

  SetTableData(val) {
    this.commonApi.getBaseData(val).subscribe(res => {
      this.reviewsDataSource.data = [];
      const arr = this.reviewsDataSource.data;
      arr.push(...res['result'].sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName)));
      this.reviewsDataSource.data = arr;
      this.sortedData = arr;
      this.tempArr = arr;
    })
    this.loader = false;
  }

  cancel() {
    this.sideModalOpen = false;
    this.resetMastercodeForm();
  }

  filterMasterCode(e) {
    this.loader = true;
    let str = e.target.value;
    let arr = this.tempArr;
    let temp = arr.filter(a => a.baseKeyValueName.toLowerCase().includes(str.toLowerCase()));
    this.reviewsDataSource.data = temp;
    this.sortedData = temp;
    this.loader = false;
  }

  deleteMasterCode(val) {
    let obj = {
      "id": val.id,
      "createdBy": val.createdBy,
      "createdOn": val.createdOn,
      "lastUpdateBy": localStorage.getItem("LoggedInUser"),
      "lastUpdateOn": new Date().toISOString(),
      "isActive": val.isActive,
      "status": val.status,
      "action": val.action,
      "baseKeyId": val.baseKeyId,
      "baseKeyValueName": val.baseKeyValueName,
      "baseKeyValueDesc": val.baseKeyValueDesc,
      "baseKeyValueJson": val.baseKeyValueJson
    }
    this.loader = true;
    this.commonApi.deleteBaseKey(obj).subscribe(res => {
      this._snackBar.open(this.selectedText + ' deleted successfully', 'GOT IT', {
        duration: 4000,
      });
      this.SetTableData(this.selectedMaster);
      this.loader = false;
    })
  }



}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
