import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { AuthService } from 'src/app/services/auth.service';
import { ApiCallService } from 'src/app/services/api-call.service';
import { CommonApiService } from './../../services/common-api.service';
import { SearchInfoComponent } from '../../shared/search-info/search-info.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  apiConfig: any;
  loginDetails: any;
  loginId = localStorage.getItem("LoggedInUser");
  messageCount: any;
  constructor(public dialog: MatDialog, private auth: AuthService, private apiCallService: ApiCallService, private router: Router, public commonApi: CommonApiService) {
    this.apiConfig = this.apiCallService.apiendPoints.Specalities;
    // this.messageCount = this.apiCallService.messageCount.getValue();
  }
  ngOnInit() {
    this.getLoginDetails(this.loginId);
    // this.getMessageCount(localStorage.getItem("LoggedInUser"));
    this.fetchStatus();
  }
  getLoginDetails(item: any) {
    this.apiCallService.getById(this.apiConfig.previewConsultant, item).subscribe(response => {
      this.loginDetails = response['result'];
    }, err => {
      console.log("Demo", err)
    })
  }
  getLastname(lastname) {
    return lastname ? lastname : ''
  }
  getMiddlename(middlename) {
    return (middlename && middlename != 'null') ? middlename : ''
  }
  openDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: { name: "dmo" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  logout() {
    this.commonApi.logOff(localStorage.getItem("LoggedInUser")).subscribe(res => {
      console.log('Logged out')
    })
    this.auth.logout();
  }

  getMessageCount(id) {
    this.commonApi.getUnreadMessageCount(id).subscribe(res => {
      // this.messageCount = res['result'];
      // this.apiCallService.messageCount.next(Number(res['result']))
      // this.messageCount = Number(res['result']);
      this.apiCallService._setMessageCount(Number(res['result']))
      this.fetchStatus();

    })
  }
  fetchStatus = () => {
    this.apiCallService.getMessageCount().subscribe(res => {
      this.messageCount = res;
    });
  }

  gotoMessage(role) {
    if (role == 'Admin') {
      this.router.navigate([`admin/messages`]);
    }
    if (role == 'User') {
      this.router.navigate([`user/messages`]);
    }
  }

  helpSearchDoc() {
    const dialogRef = this.dialog.open(SearchInfoComponent, {
      data: { name: "dmo" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
