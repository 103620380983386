import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';


export interface PeriodicElement {
  company: string;
  consultantName: string;
  phoneNo: number;
  speciality: string;
  country: string;
  status: string;
  actions: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },
  { consultantName: 'Demo', company: 'Hydrogen', phoneNo: 99999999999, speciality: 'H', country: 'UK', status: 'Yes', actions: '' },

];

@Component({
  selector: 'app-review-ratings',
  templateUrl: './review-ratings.component.html',
  styleUrls: ['./review-ratings.component.scss']
})


export class ReviewRatingsComponent implements OnInit {

  displayedColumns: string[] = ['consultantName', 'company', 'phoneNo', 'speciality', 'country', 'status', 'actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  advanceSearchShow:boolean = false;

  constructor() { }

  ngOnInit() {
    this.dataSource.sort = this.sort;

  }

  toggleAdvanceSearch(){
    this.advanceSearchShow = !this.advanceSearchShow;
  }
  clearFilter(){
    // reset filter Selection...
  }

}
