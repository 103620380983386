import { Component, OnInit, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ApiCallService } from "src/app/services/api-call.service";
import { CommonApiService } from "./../../services/common-api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

let REVIEWS_DATA = [];
let HISTORY_DATA = [];

@Component({
  selector: "app-consultant-view",
  templateUrl: "./consultant-view.component.html",
  styleUrls: ["./consultant-view.component.scss"],
})
export class ConsultantViewComponent implements OnInit {
  @Input() data: any;
  DialogData: any;
  previewDatails: any;
  apiConfig: any;
  reqParamsId: any;
  redirectURL: string;
  loader = true;
  showReviews = false;
  showHistory = false;
  rateModal = false;
  selectedReview = [];
  selectedConsultents = [];
  step = 1;
  arr = new Array(5);
  loggedInUser = Number(localStorage.getItem("LoggedInUser"));
  userRole = localStorage.getItem("LoginRole");

  specialitiesData = [];
  industriesData = [];

  reviewsDataSource = new MatTableDataSource(REVIEWS_DATA);
  historyDataSource = new MatTableDataSource(HISTORY_DATA);

  displayedColumns3: string[] = [
    "reviewer",
    "lastModified",
    "trust",
    "delivery",
    "useAgain",
    "contact",
    "status",
    "action",
  ];
  displayedColumns4: string[] = [
    "name",
    "company",
    "lastupdateddate",
    "lastupdatedby",
    "action",
  ];

  constructor(
    private router: Router,
    public apiCallService: ApiCallService,
    private route: ActivatedRoute,
    public commonApi: CommonApiService,
    private _snackBar: MatSnackBar
  ) {
    this.apiConfig = this.apiCallService.apiendPoints.Specalities;
    this.reqParamsId = this.route.snapshot.paramMap.get("personId");
    this.redirectURL = this.route.snapshot.queryParams.redirect;
  }

  ngOnInit() {
    this.previewConsultant(
      this.reqParamsId ? this.reqParamsId : localStorage.getItem("LoggedInUser")
    );
    this.GetSpecialities();
    this.GetIndustries();
  }

  fetchReviews() {
    this.showReviews = !this.showReviews;
    if (this.reviewsDataSource.data.length == 0) {
      this.GetReviewsData();
    }
  }

  closeMoreDetails() {
    this.router.navigate([this.redirectURL]);
  }

  fetchConsultantHistory() {
    this.showHistory = !this.showHistory;
    if (this.historyDataSource.data.length == 0) {
      this.GetConsultantsHistory(this.reqParamsId);
    }
  }

  getEmailAddress(email: string): string {
    return email ? `mailto:${email}` : "";
  }

  getLastname(lastname) {
    return lastname ? lastname : "";
  }
  getMiddlename(middlename) {
    return middlename && middlename != "null" ? middlename : "";
  }
  getRatingCount(rating, rateNum) {
    let rate =
      rating.personReviewDetails &&
      rating.personReviewDetails.find((a) => a.reviewQnsId == rateNum);
    return (rate && rate.reviewAns) || 0;
  }

  GetSpecialities() {
    this.commonApi.getBaseData(2).subscribe((data) => {
      this.specialitiesData = data["result"];
    });
  }
  GetIndustries() {
    this.commonApi.getBaseData(4).subscribe((data) => {
      this.industriesData = data["result"];
    });
  }

  GetConsultantsHistory(id) {
    this.loader = true;
    this.commonApi.getConsultentHistory(id).subscribe((res) => {
      this.historyDataSource.data = [];
      let data = res["result"];
      const arr = this.historyDataSource.data;
      arr.push(...data);
      this.historyDataSource.data = arr;
      this.loader = false;
    });
  }

  shouldContact(rating) {
    let rate =
      rating.personReviewDetails &&
      rating.personReviewDetails.find((a) => a.reviewQnsId == 4758);
    return (rate && (rate.reviewAns == 1 ? "Yes" : "No")) || "";
  }

  GetReviewsData() {
    this.reviewsDataSource.data = [];
    this.loader = true;
    this.commonApi
      .getReviewByPerson(
        this.reqParamsId,
        Number(localStorage.getItem("LoggedInUser"))
      )
      .subscribe((res) => {
        this.reviewsDataSource.data = [];
        let data = res["result"];
        const arr = this.reviewsDataSource.data;
        arr.push(...data);
        this.reviewsDataSource.data = arr;
        this.loader = false;
      });
  }

  openRateModal(data) {
    this.rateModal = true;
    this.selectedReview = data;
  }

  getStatus(data) {
    switch (data.reviewStatusId) {
      case 4751:
        return "Submitted";
      case 4752:
        return "Approved";
      case 4753:
        return "Rejected";
      case 4754:
        return "Saved";
      default:
        return "Saved";
    }
  }

  getConvertedDate(date) {
    let d = new Date(date);
    let tmp = d.toDateString().split(" ");
    let afterDate = tmp[2] + " " + tmp[1] + ", " + tmp[3];
    let time = d.toLocaleTimeString().split(":");
    let afterTime = time[0] + ":" + time[1] + " " + time[2].split(" ")[1];
    return afterDate + " " + afterTime;
  }

  AddConsuntant(id) {
    if (this.selectedConsultents.indexOf(id) != -1) {
      this._snackBar.open("This record is already added", "GOT IT", {
        duration: 4000,
      });
    } else if (this.selectedConsultents.length >= 2) {
      this._snackBar.open("Can not add more than two records", "GOT IT", {
        duration: 4000,
      });
    } else {
      this.selectedConsultents.push(id);
      this._snackBar.open("Added record to compare", "GOT IT", {
        duration: 4000,
      });
    }
  }

  gotoCompare(role) {
    let [historyId1, historyId2] = this.selectedConsultents;
    if (role == "Admin") {
      this.router.navigate([
        `admin/compare-history/${historyId1}/${historyId2}`,
      ]);
    }
    if (role == "User") {
      this.router.navigate([
        `user/compare-history/${historyId1}/${historyId2}`,
      ]);
    }
  }

  getBackground(row) {
    return this.selectedConsultents.indexOf(row.historyId) == -1 ? false : true;
  }

  previewConsultant(reqParamsId) {
    this.apiCallService
      .getById(
        this.apiConfig.previewConsultant,
        `${reqParamsId}?pageContext=1&userId=${this.loggedInUser}`
      )
      .subscribe(
        (response) => {
          this.previewDatails = response["result"];
          this.loader = false;
        },
        (err) => {
          console.log("Demo", err);
        }
      );
  }
}
