import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ApiCallService } from "src/app/services/api-call.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  BasicFormGroup: any;
  apiConfig: any;
  submitted = false;
  loader = false;
  showOtp:boolean=false;
  constructor(
    public thisDialogRef: MatDialogRef<ForgotPasswordComponent>,
    public ApiCallService: ApiCallService,
    private _snackBar: MatSnackBar,
    private fb:FormBuilder,
  ) {
    this.apiConfig = this.ApiCallService.apiendPoints.logIn;
   }

  ngOnInit() {
    this.createChangePassword();
  }

  createChangePassword() {
    this.BasicFormGroup = this.fb.group(
      {
        username: ['', [Validators.required]],
        otp:[''],
        newpassword: [''],
        confirmpassword: [''],
      },
      { validator: this.MustMatch("newpassword", "confirmpassword") }
    );
  }
  sendOtp(username:string){
    if(username){
      this.loader = true;
      this.ApiCallService.getOTP(
        this.apiConfig.otp, username).subscribe(
          (res) => {
            if (res["status"] == 1) {              
              this.showOtp=true;
              this._snackBar.open("OTP Sent successfully", "GOT IT", {
                duration: 4000,
              });
              this.loader = false;
            } else {
              this._snackBar.open(res["message"], "GOT IT", {
                duration: 4000,
              });
              this.loader = false;
            }
          },
          (err) => {
            console.log("Demo", err);
          }
        );
    }
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  onFormSubmit(form:FormGroup){
    this.submitted = true;
    if (form.valid && form.value.newpassword && form.value.confirmpassword ) {
      this.loader = true;
      let formdata = form.value;
      let data = {
        id: 0,
        createdBy: "string",
        createdOn: new Date().toISOString(),
        lastUpdateBy: "string",
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 0,
        action: 0,
        username: formdata.username,
        password: formdata.newpassword,
        oldPassword: formdata.otp,
        securityQnsId: 0,
        secutiryAns: "",
      };
      this.ApiCallService.putWithoutId(
        this.apiConfig.ResetPassword,
        data
      ).subscribe(
        (res) => {
          if (res["status"] == 1) {
            this._snackBar.open("Password updated successfully", "GOT IT", {
              duration: 4000,
            });
            this.loader = false;
            this.onCloseConfirm();
          } else {
            this._snackBar.open(res["message"], "GOT IT", {
              duration: 4000,
            });
            this.loader = false;
          }
        },
        (err) => {
          console.log("Demo", err);
        }
      );
    } else {
      this.loader=false
      this._snackBar.open("Enter Password", "GOT IT", {
        duration: 1000,
      });
    }
  }
  onCloseConfirm() {
    this.thisDialogRef.close("Confirm");
  }
}
