import { Component, OnInit } from '@angular/core';
import{SharedService} from '../../services/shared.service';
import { ApiCallService } from 'src/app/services/api-call.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  constructor(public apiCallService:ApiCallService) { 
  }


  ngOnInit() {
  }

}
