import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import * as Model from "./../../model/ReviewModel";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommonApiService } from "./../../services/common-api.service";
import { ApiCallService } from "src/app/services/api-call.service";
import { MONTHS } from "../../shared/constants";
import { DOCUMENT } from "@angular/common";
import { SharedService } from "src/app/services/shared.service";

let REFERENCE_DATA = [];
let EMP_DATA = [];
let PERMISSION_DATA = [];

let RECENT_SEARCHES_DATA: Model.RecentSearchModel[] = [];

let REVIEWS_DATA = [];

@Component({
  selector: "app-review-a-consultant",
  templateUrl: "./review-a-consultant.component.html",
  styleUrls: ["./review-a-consultant.component.scss"],
})
export class ReviewAConsultantComponent implements OnInit {
  referenceForm: FormGroup;
  personDetailsForm: FormGroup;
  companyForm: FormGroup;
  specialitiesForm: FormGroup;
  biographyForm: FormGroup;
  empdetailsForm: FormGroup;
  referencesForm: FormGroup;
  notesForm: FormGroup;
  addBaseValueForm: FormGroup;
  ratingsForm: FormGroup;
  displayValuesForm: FormGroup;
  submitUserForm: FormGroup;
  addPermissionForm: FormGroup;

  displayedColumns: string[] = [
    "name",
    "company",
    "phone",
    "email",
    "comments",
  ];
  displayedColumns2: string[] = ["company", "title", "completionDate"];
  displayedColumns3: string[] = [
    "reviewer",
    "lastModified",
    "trust",
    "delivery",
    "useAgain",
    "contact",
  ];
  displayedColumns4: string[] = [
    "employername",
    "title",
    "duration",
    "employmentOrder",
  ];
  // displayedColumns5: string[] = ['note', 'createdon', 'createdby', 'action'];
  displayedColumns6: string[] = [
    "permissionLevel",
    "updatedon",
    "updatedby",
    "actions",
  ];

  EmploymentDetails = [];

  refDataSource = new MatTableDataSource(REFERENCE_DATA);
  recentDataSource = new MatTableDataSource(RECENT_SEARCHES_DATA);
  reviewsDataSource = new MatTableDataSource(REVIEWS_DATA);
  empDataSource = new MatTableDataSource(EMP_DATA);
  permissionDataSource = new MatTableDataSource(PERMISSION_DATA);

  reqParamsId: any;
  apiConfig: any;
  previewDatails: any;
  userRole = localStorage.getItem("LoginRole");
  apiconfig: any;
  title: any;
  pageType: any;
  imgPresent = false;
  showAll = false;
  reviewToastMsg = "submitted ";
  showMessageStripe = false;
  showErrorToast = false;
  reviewData = [];
  tempReviewArr = [];
  permissionModal: Boolean = false;
  star1 = 0;
  star2 = 0;
  star3 = 0;
  setRating = 0;
  savetype = "saved";
  showMore = false;
  invalidEmail = false;
  emailErrorMsg = "";
  emailValidating = false;
  isConsultant: boolean = false;
  titleModalOpen: boolean = false;
  cityModalOpen: boolean = false;
  newTitle: string = "";
  newCity: string = "";
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
    private form: FormBuilder,
    public commonApi: CommonApiService,
    private route: ActivatedRoute,
    private router: Router,
    public apiCallService: ApiCallService,
    public sharedService: SharedService,
  ) {
    this.reqParamsId = this.route.snapshot.paramMap.get("personId");
    this.apiconfig = this.apiCallService.apiendPoints.personDetails;
    this.route.data.subscribe((data) => {
      this.title = data.title;
      this.pageType = data.pageType;
    });
  }

  arr = new Array(5);
  step = 0;
  EXPANDED = {
    CONSULTANT_DETAILS: false,
    SPECIALTIES: false,
    NOTES: false,
    BIOGRAPHY: false,
    EMP_DETAILS: false,
    RECENT_SEARCHES: false,
    REVIEWS: false,
    PERMISSION: false
  }
  imgURL = "";
  sideModal = false;
  showNotes = false;
  notesModal = false;
  rateModal = false;
  sidsAddCodeModalOpen = false;
  services_name = "services";
  functions_name = "functions";
  inductries_name = "industries";
  sideModalData = {};
  notesData = [];
  tempNotes = [];
  createdDate = "";
  reviewQuestions = [];
  permissionsData: any = [];
  // tempService = [];
  // tempFunction = [];
  // tempIndustry = [];

  saveServices = [];
  saveFunctions = [];
  saveIndustries = [];

  refModalOpen = false;
  empModalOpen = false;
  companyModalOpen = false;
  refEditMode = false;
  empEditMode = false;
  RefFormGroup: any;

  salutationData = [];
  personId = 0;
  recordStatusData = [];
  titleData = [];
  countryData = [];
  stateData = [];
  cityData = [];
  regionData = [];
  selectedCompany = null;
  selectedCompanyRef = null;
  selectedTitle = null;
  selectedTitleEmp = null;
  allowSearch = true;
  timer = null;
  searching = false;
  searchingTitle = false;
  tabMessage = "";
  loader = false;
  isNotesModel = 0;
  fromGrid: any = 0;

  // STORE ALL SPECIALITIES CHECKBOXES VALUES
  servicesCheckboxes = [];
  functionsCheckboxes = [];
  industriesCheckboxes = [];

  selectedServices = [];
  selectedFunctions = [];
  selectedIndustries = [];

  companyDropdownData = [];
  titleDropdownData = [];

  setStep(KEY: string) {
    this.EXPANDED[KEY] = !this.EXPANDED[KEY];
  }

  ngOnInit() {
    if (localStorage.getItem("LoginRole") == "Consultant") {
      this.reqParamsId = localStorage.getItem("LoggedInUser");
    }
    // this.ReferenceForm();
    this.isConsultant = this.userRole === "Consultant";
    this.PersonDetailsForm();
    this.CompanyDetailsForm();
    this.SpecialityForm();
    this.BiographyForm();
    this.NotesForm();
    this.EmployeeDetailsForm();
    this.ReferencesForm();
    this.AddBaseValueForm();
    this.RatingForm();
    this.displayForm();
    this.PermissionForm();
    this.SubmitUserForm();

    if (this.userRole === "Admin" && this.reqParamsId) {
      this.getAllPermissions();
      this.getPermisionById(this.reqParamsId);
    }

    this.empDataSource.data = [];
    // SET INPUT FIELDS VALUES WHEN COME TO REVIEW

    if (this.reqParamsId) {
      // this.getCompanyByPerson(this.reqParamsId);
      this.getTitleByPerson(this.reqParamsId);
      this.setConsultantData(this.reqParamsId);
      this.GetEmploymentDetails(this.reqParamsId);
      this.GetReferences(this.reqParamsId);
      this.GetSpecialities(this.reqParamsId);
      this.GetReviewsData();
      this.GetNotes(this.isNotesModel);
      this.SetPermissionData();
      this.GetRecentSearches(this.reqParamsId);
      // this.specialitiesForm.patchValue({ personId: this.reqParamsId })
    }

    this.SetSalutationData();
    this.SetRecordStatusData();
    this.SetCountryData();
    this.SetStateData();
    this.SetRegionData();
    this.SetCityData();
    this.SetServicesData();
    this.SetFunctionsData();
    this.SetIndustriesData();
    this.GetReviewQuestions();

    this.fromGrid = this.route.snapshot.paramMap.get('ind');
  }


  backbtn() {
    if (this.fromGrid == 1) {
      this.router.navigate([`admin/consultants/1`]);
    } else if (this.fromGrid == 3) {
      this.router.navigate([`admin/dashboard`]);
    } else if (this.fromGrid == 4) {
      this.router.navigate([`admin/view-non-consultants`]);
    } else {
      this.router.navigate([`admin/review-new-consultant`]);
    }

  }

  openDialog() {
    const dialogRef = this.dialog.open(PopupDialouge);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.addPermissionForm.patchValue({
          actionID: 1,
        });
        this.commonApi
          .updatePermission(this.addPermissionForm.value)
          .subscribe((res) => {
            this._snackBar.open(
              `Email sent successfully to ${this.personDetailsForm.value.firstName}`,
              "GOT IT",
              {
                duration: 4000,
              }
            );
          });
      }
    });
  }

  saveNewTitle() {
    if (!this.newTitle) {
      return;
    }
    const data = {
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      baseKeyId: 15,
      baseKeyValueName: this.newTitle,
      baseKeyValueDesc: "",
      baseKeyValueJson: ""
    }
    this.commonApi.addBaseValue(data).subscribe((res) => {
      this.titleModalOpen = false;
      this.sharedService.showToast("Title added successfully");
    })
  }

  saveNewCity() {
    if (!this.newCity) {
      return;
    }
    const data = {
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      baseKeyId: 8,
      baseKeyValueName: this.newCity,
      baseKeyValueDesc: "",
      baseKeyValueJson: ""
    }
    this.commonApi.addBaseValue(data).subscribe((res) => {
      this.cityModalOpen = false;
      this.SetCityData();
      this.sharedService.showToast("City added successfully");
    })
  }

  SetSalutationData() {
    this.commonApi.getBaseData(16).subscribe((data) => {
      this.salutationData = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }

  SetServicesData() {
    this.commonApi.getBaseData(3).subscribe((data) => {
      this.servicesCheckboxes = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }
  SetFunctionsData() {
    this.commonApi.getBaseData(2).subscribe((data) => {
      this.functionsCheckboxes = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }
  SetIndustriesData() {
    this.commonApi.getBaseData(4).subscribe((data) => {
      this.industriesCheckboxes = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }

  SetRecordStatusData() {
    this.commonApi.getBaseData(9).subscribe((data) => {
      this.recordStatusData = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
      if (this.userRole == "User") {
        this.recordStatusData = this.recordStatusData.filter(
          (a) => a.id != 1488 && a.id != 1489
        );
      }
    });
  }
  // SetTitleData(){
  //   this.commonApi.getBaseData(15).subscribe((data) => {this.titleData = data['result'];})
  // }
  GetReviewQuestions() {
    this.commonApi.getBaseData(20).subscribe((data) => {
      this.reviewQuestions = data["result"];
    });
  }

  SetCountryData() {
    this.commonApi.getBaseData(5).subscribe((data) => {
      this.countryData = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }

  SetRegionData() {
    this.commonApi.getBaseData(6).subscribe((data) => {
      this.regionData = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }

  SetStateData() {
    this.commonApi.getBaseData(7).subscribe((data) => {
      this.stateData = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }

  SetCityData() {
    this.commonApi.getBaseData(8).subscribe((data) => {
      this.cityData = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }

  setConsultantData(id) {
    this.loader = true;
    this.commonApi.getConsultant(id, 0, this.reqParamsId).subscribe((res) => {
      // console.log(res);
      // let data = res["result"];
      let { result: data = {} }: any = res || {};
      data = data || {};
      this.createdDate = data.createdOn;
      this.imgPresent = data.photoUrl != "null" ? true : false;
      this.addPermissionForm.patchValue({
        personEmail:
          (data && data.contactDetails && data.contactDetails.email) || "",
      });
      this.personDetailsForm.patchValue({
        id: data.id,
        status: 0,
        action: 0,
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        middleName: data.middleName || "",
        personType: data.personType,
        salutation: data.salutation,
        title: data.title,
        companyId: data.companyId,
        recordStatus: data.recordStatus,
        createdOn:
          this.getConvertedDate(data.createdOn) || new Date().toISOString(),
        lastUpdateOn:
          this.getConvertedDate(data.lastUpdateOn) || new Date().toISOString(),
        companyAddressId: data.companyAddressId || 0,
        photoUrl: data.photoUrl || "",
        bioData: data.bioData || "",
        createdByName:
          data.createdByName && data.createdByName.split("null").join(""),
        latUpdatedByName:
          data.latUpdatedByName && data.latUpdatedByName.split("null").join(""),
        regionID: data.regionID || null,
        personAddress: {
          line1: data.personAddress && data.personAddress.line1 || "",
          line2: data.personAddress && data.personAddress.line2 || "",
          line3: data.personAddress && data.personAddress.line3 || "",
          cityId: data.personAddress && data.personAddress.cityId || 0,
          stateId: data.personAddress && data.personAddress.stateId || 0,
          countryID: data.personAddress && data.personAddress.countryID || 0,
          postalCode: data.personAddress && data.personAddress.postalCode || "",
          country: data.personAddress && data.personAddress.country || 0,
          addressType: data.personAddress && data.personAddress.addressType || null,
          cityName: data.personAddress && data.personAddress.cityName || "",
          stateName: data.personAddress && data.personAddress.stateName || "",
          countryName: data.personAddress && data.personAddress.countryName || "",
          id: data.personAddress && data.personAddress.id || 0,
          createdBy:
            data.personAddress && data.personAddress.createdBy ||
            localStorage.getItem("LoggedInUser"),
          /*createdOn: data.personAddress && data.personAddress.createdOn || new Date().toISOString(),*/
          createdOn:
            data.personAddress && data.personAddress.createdOn ||
              data.personAddress && data.personAddress.createdOn.includes("0001-01-01")
              ? new Date().toISOString()
              : data.personAddress && data.personAddress.createdOn,
          lastUpdateBy: localStorage.getItem("LoggedInUser"),
          //lastUpdateOn:
          //  data.personAddress && data.personAddress.lastUpdateOn || new Date().toISOString,
          lastUpdateOn:
            data.personAddress && data.personAddress.lastUpdateOn ||
              data.personAddress && data.personAddress.lastUpdateOn.includes("0001-01-01")
              ? new Date().toISOString()
              : data.personAddress && data.personAddress.lastUpdateOn,
          isActive: data.personAddress && data.personAddress.isActive || true,
          status: data.personAddress && data.personAddress.status || 1,
          action: data.personAddress && data.personAddress.action || 0,
        },
        contactDetails: {
          id: data.contactDetails ? data.contactDetails.id : 0,
          personId: data.contactDetails ? data.contactDetails.personId : 0,
          email: data.contactDetails ? data.contactDetails.email : "",
          phone: data.contactDetails ? data.contactDetails.phone : "",
          fax: data.contactDetails ? data.contactDetails.fax : "",
          websiteURL: data.contactDetails ? data.contactDetails.websiteURL : "",
          linkedInprofile: data.contactDetails
            ? data.contactDetails.linkedInprofile
            : "",
          createdOn: data.contactDetails
            ? data.contactDetails.createdOn
            : new Date().toISOString(),
        },
      });
      this.selectedCompany = data.company;
      let arr = [...data.personNoteHistory || []];
      for (let i = 0; i < arr.length; i++) {
        arr[i].action = 3;
      }
      this.personId = data.id;
      this.personDetailsForm.value.personNoteHistory.push(...arr);
      this.biographyForm.patchValue({
        bioData: data.bioData,
      });
      this.companyForm.patchValue({
        // id: data.company.id,
        companyName: data.company && data.company.companyName,
      });
    });
    this.loader = false;
  }

  getPersonType() {
    switch (this.pageType) {
      case "admin":
        return 1;
      case "user":
        return 2;
      default:
        return 3;
    }
  }

  displayForm() {
    this.displayValuesForm = this.form.group({
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      answer6: "",
      answer7: "",
      answer8: "",
      answer9: "",
      answer10: "",
      answer11: "",
      answer12: "",
      answer13: "",
      answer14: "",
      answer15: "",
    });
  }

  PersonDetailsForm() {
    this.personDetailsForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: this.getConvertedDate(new Date().toISOString()),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: this.getConvertedDate(new Date().toISOString()),
      isActive: true,
      status: 0,
      createdByName: "",
      latUpdatedByName: "",
      action: 0,
      companyId: 0,
      firstName: "",
      lastName: "",
      middleName: "",
      salutation: 0,
      title: 0,
      webSite: "",
      personType: this.getPersonType(),
      idStatus: 0,
      esiXstatusId: 0,
      dataStatusId: 0,
      directoryStatusId: 0,
      recordStatus: 1490,
      reviewedById: 0,
      bioData: "",
      contactid: 0,
      photo: null,
      photoUrl: "",
      personAddress: this.form.group({
        line1: "",
        line2: "",
        line3: "",
        cityId: 0,
        stateId: 0,
        countryID: 0,
        postalCode: "",
        country: 0,
        addressType: null,
        cityName: null,
        stateName: null,
        countryName: null,
        id: 0,
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: null,
        status: 1,
        action: 0,
      }),
      personNoteHistory: this.form.array([]),
      contactDetails: this.form.group({
        id: 0,
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 0,
        action: 0,
        personId: 0,
        email: "",
        phone: "",
        ext: "",
        directPhone: "",
        fax: "",
        assistantName: "",
        asstPhone: "",
        asstExt: "",
        asstEmail: "",
        websiteURL: "",
        linkedInprofile: "",
      }),
      avgRatingsDTO: this.form.group({
        process: 1,
        delivered: 1,
        useAgain: 1,
        overall: 1,
        reviewCount: 1,
      }),
      company: this.form.group({
        id: 0,
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 1,
        action: 1,
        companyName: "",
        aboutCompany: "",
        companyWebsiteUrl: "",
        phoneNumber1: "",
        phoneNumber2: "",
        addressId: 0,
        companyAddressId: 0,
        isDiversity: false,
        address: this.form.group({
          id: 0,
          createdBy: localStorage.getItem("LoggedInUser"),
          createdOn: new Date().toISOString(),
          lastUpdateBy: localStorage.getItem("LoggedInUser"),
          lastUpdateOn: new Date().toISOString(),
          isActive: true,
          status: 1,
          action: 1,
          line1: "",
          line2: "",
          line3: "",
          cityId: 0,
          stateId: 0,
          countryID: 0,
          postalCode: "",
          country: 0,
          addressType: 0,
          cityName: "",
          stateName: "",
          countryName: "",
        }),
      }),
      companyAddressId: 0,
      regionID: 0,
    });
  }

  CompanyDetailsForm() {
    this.companyForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 2,
      companyName: "",
      aboutCompany: "",
      companyWebsiteUrl: "",
      phoneNumber1: "",
      phoneNumber2: "",
      addressId: 0,
      isDiversity: false,
      address: this.form.group({
        id: 0,
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 0,
        action: 2,
        line1: "",
        line2: "",
        line3: "",
        cityId: 0,
        stateId: 0,
        postalCode: "",
        country: null,
        addressType: 0,
      }),
    });
  }

  SpecialityForm() {
    this.specialitiesForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 3,
      personId: Number(this.reqParamsId),
      specialityTypeId: 0,
      specialityId: 0,
      hasInd: false,
      wgawInd: true,
      specialityName: "",
      uniqueId: new Date().getTime(),
    });
  }

  BiographyForm() {
    this.biographyForm = this.form.group({
      personId: Number(this.reqParamsId),
      bioData: "",
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
    });
  }

  NotesForm() {
    this.notesForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 2,
      personId: Number(this.reqParamsId),
      noteText: "",
      noteStatus: 0,
    });
  }

  EmployeeDetailsForm() {
    this.empdetailsForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 2,
      personId: Number(this.reqParamsId),
      employerName: "",
      titleId: 0,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      employmentOrder: 0,
      titleName: "",
      emplDuration: "",
      uniqueId: new Date().getTime(),
    });
  }

  ReferencesForm() {
    this.referencesForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 2,
      personId: Number(this.reqParamsId),
      refName: "",
      refCompany: 0,
      refphone: "",
      refEmail: "",
      comments: "",
      refCompanyName: "",
    });
  }

  AddBaseValueForm() {
    this.addBaseValueForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      baseKeyId: 0,
      baseKeyValueName: "",
      baseKeyValueDesc: "",
      baseKeyValueJson: "",
    });
  }

  RatingForm() {
    this.ratingsForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      personId: 0,
      personInvitedById: 0,
      personReviewedById: Number(localStorage.getItem("LoggedInUser")),
      reviewStatusId: 4751,
      comments: "",
      personReviewDetails: this.form.array([]),
    });
  }

  PermissionForm() {
    this.addPermissionForm = this.form.group({
      roleId: 0,
      roleName: "",
      personID: Number(this.reqParamsId),
      personEmail: "",
      registrationLink: `${this.document.location.origin}/signup/${this.reqParamsId}`,
      lastUpdateByName: "",
      isActive: false,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      actionID: 2,
    });
  }

  SubmitUserForm() {
    this.submitUserForm = this.form.group({
      person: {},
      specialities: [],
      employmentDetails: [],
      references: [],
      reviews: [],
    });
  }

  SubmitUserData() {
    this.personDetailsForm.patchValue({
      recordStatus: 1487,
      createdOn: new Date(this.personDetailsForm.value.createdOn).toISOString(),
      lastUpdateOn: new Date(
        this.personDetailsForm.value.lastUpdateOn
      ).toISOString(),
    });
    let review = this.reviewsDataSource.data;
    if (review.length > 0) {
      // review.map(a=> a.reviewStatusId = 4751);
      for (let i = 0; i < review.length; i++) {
        if (
          review[i].reviewStatusId != 4752 &&
          review[i].reviewStatusId != 4752
        )
          review[i].reviewStatusId = 4751;
      }
    }
    this.submitUserForm.patchValue({
      specialities: [
        ...this.saveServices,
        ...this.saveFunctions,
        ...this.saveIndustries,
      ],
      reviews: review,
      person: this.personDetailsForm.value,
    });

    this.loader = true;
    this.savetype = "submit";
    this.commonApi
      .submitConsultantData(this.submitUserForm.value)
      .subscribe((res) => {
        this.showMessageStripe = true;
        window.scrollTo(0, 0), (this.tabMessage = this.getTabMessage("user"));
        this.loader = false;
        setInterval(() => {
          this.showMessageStripe = false;
        }, 4000);
      });
  }

  SubmitBaseValueForm(val, type) {
    this.loader = true;
    this.commonApi
      .addBaseValue(this.addBaseValueForm.value)
      .subscribe((res) => {
        this._snackBar.open(`New ${val} added successfully`, "GOT IT", {
          duration: 4000,
        });
        this.SetServicesData();
        this.SetFunctionsData();
        this.SetIndustriesData();
        setTimeout(() => {
          this.openSideModal(type);
        }, 400);
        this.sidsAddCodeModalOpen = false;
        this.sideModal = true;
        // this.GetSpecialities(this.reqParamsId);
        this.loader = false;
        this.addBaseValueForm.reset();
      });
  }

  NotesSubmit(type, data) {
    if (data.noteText != "") {
      this.tempNotes = data;
      this.showMessageStripe = true;
      this.loader = true;
      this.tabMessage = this.getTabMessage(type);
      this.commonApi.addNotes(this.tempNotes).subscribe((res: any) => {
        if (res && res.result) {
          window.scrollTo(0, 0),
            (this.showMessageStripe = true),
            (this.loader = false);
          this.notesForm.patchValue({ noteText: "" });
          this.GetNotes(this.isNotesModel);
          this.tempNotes = [];
        }
      });
    }
  }

  GetNotes(id) {
    if (id == 1) {
      this.notesModal = true;
    }
    this.commonApi.getNotes(this.reqParamsId).subscribe((res) => {
      this.notesData = res["result"];
    });
  }

  GetNoteHistory(id) {
    this.commonApi.getNote(id).subscribe((res: any) => {
      this.notesForm.patchValue(res.result);
      this.notesForm.get("action").setValue(3);
      // this.editNoteText = res.result.noteText;
      this.notesModal = false;
      // this.editNotesModal = true;
    });
  }

  DeleteNotes(data: any, type: number) {
    this.commonApi.addNotes(data).subscribe((res: any) => {
      if (res && res.result) {
        this.GetNotes(1)
      }
    })
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isValidEmail(event) {
    let key = event.target.value;
    if (key.length > 3 && this.validateEmail(key)) {
      clearTimeout(this.timer);
      this.emailValidating = true;
      if (!this.reqParamsId) {
        this.timer = setTimeout(() => {
          this.commonApi.validateEmail(key).subscribe((res) => {
            let exist = res["result"];
            if (exist) {
              console.log(exist);
              this.emailErrorMsg = "Email already exists";
              this.invalidEmail = true;
              this.emailValidating = false;
            } else {
              this.emailErrorMsg = "";
              this.invalidEmail = false;
              this.emailValidating = false;
            }
          });
        }, 600);
      } else {
        this.invalidEmail = false;
        this.emailValidating = false;
        this.emailErrorMsg = "";
      }
    } else {
      this.emailErrorMsg = "Email is not valid";
      this.invalidEmail = true;
      this.emailValidating = false;
    }
  }

  // SAVING FORM DATA FUNCTIONS
  AddPersonDetailsForm(event, data, type) {
    event.preventDefault();
    this.emailErrorMsg = "";
    this.invalidEmail = false;
    this.loader = true;
    this.tabMessage = this.getTabMessage(type);
    this.showMessageStripe = true;
    data.personAddress.cityId = Number(data.personAddress.cityId);
    data.personAddress.stateId = Number(data.personAddress.stateId);
    data.personAddress.country = Number(data.personAddress.country);
    data.personAddress.countryID = Number(data.personAddress.country);
    data.regionID = Number(data.regionID);
    data.createdOn = new Date(data.createdOn).toISOString();
    data.lastUpdateOn = new Date(data.lastUpdateOn).toISOString();

    if (this.reqParamsId) {
      this.commonApi.updateConsultant(data).subscribe((res) => {
        window.scrollTo(0, 0),
          this.addPermissionForm.patchValue({
            personEmail: res["result"].contactDetails.email || "",
          });
        (this.showMessageStripe = true),
          // setInterval(() => {this.showMessageStripe = false}, 4000),
          (this.loader = false),
          setTimeout(() => {
            this.userRole != "Consultant" &&
              this.router.navigate([
                this.userRole === "Admin"
                  ? `admin/review-new-consultant`
                  : `user/suggest-consultant`,
              ]);
          }, 2000);
      });
    } else {
      const {
        contactDetails: { email },
      } = data;
      this.commonApi.validateEmail(email).subscribe((res) => {
        let exist = res["result"];
        this.loader = false;
        if (exist) {
          this._snackBar.open(`Email already exists`, "GOT IT", {
            duration: 4000,
          });
          this.invalidEmail = true;
          this.emailErrorMsg = "Email already exists";
          this.loader = false;
        } else {
          this.commonApi.addConsultant(data).subscribe((res) => {
            this.personDetailsForm.disable(),
              window.scrollTo(0, 0),
              (this.showMessageStripe = true),
              this.specialitiesForm.patchValue({
                personId: res["result"]["id"],
              });
            this.addPermissionForm.patchValue({
              personEmail: res["result"].contactDetails.email || "",
            });
            this.loader = false;
            setTimeout(() => {
              this.userRole != "Consultant" &&
                this.router.navigate([
                  this.userRole === "Admin"
                    ? `admin/review-consultant/${res["result"]["id"]}`
                    : `user/review-consultant/${res["result"]["id"]}`,
                ]);
            }, 2000);
          });
        }
      });
    }
  }

  GetRecentSearches(id) {
    this.commonApi.GetRecentSearches(id).subscribe((res) => {
      const { result }: any = res;
      this.recentDataSource.data = result;
    });
  }

  getCompletionDate(month, year) {
    return `${MONTHS[month] || ""} ${year || ""}`;
  }

  setSalucation(e) {
    this.personDetailsForm.patchValue({ salutation: Number(e.target.value) });
    // console.log(e.target.value)
  }

  // SAVING FORM DATA FUNCTIONS
  AddCompanyDetailsForm(data, type) {
    // console.log(data)
    // console.log(this.companyForm.value)
    this.loader = true;
    this.tabMessage = this.getTabMessage(type);
    this.commonApi.addCompany(data).subscribe((res) => {
      // this.companyForm.disable(),
      window.scrollTo(0, 0),
        (this.companyModalOpen = false),
        (this.showMessageStripe = true),
        setInterval(() => {
          this.showMessageStripe = false;
        }, 4000);
      this.personDetailsForm.patchValue({
        companyId: res["result"]["id"],
      }),
        (this.loader = false);
    });
  }

  SetCountry(event) {
    console.log(event.target.value);
    // this.companyForm.value.address.country = Number(event.target.value);
    this.companyForm.patchValue({
      address: { country: Number(event.target.value) },
    });
  }
  SetCity(event) {
    console.log(event.target.value);
    // this.companyForm.value.address.cityId = Number(event.target.value);
    this.companyForm.patchValue({
      address: { cityId: Number(event.target.value) },
    });
  }
  SetState(event) {
    console.log(event.target.value);
    // this.companyForm.value.address.stateId = Number(event.target.value);
    this.companyForm.patchValue({
      address: { stateId: Number(event.target.value) },
    });
  }

  // SAVING SPECIALITIES DATA INTO DB
  AddSpecialityDetails() {
    this.loader = true;
    this.tabMessage = this.getTabMessage("speciality");
    this.commonApi
      .addSpecialities([
        ...this.saveServices,
        ...this.saveFunctions,
        ...this.saveIndustries,
      ])
      .subscribe((res) => {
        window.scrollTo(0, 0), (this.saveServices = []);
        this.saveFunctions = [];
        this.saveIndustries = [];
        this.selectedFunctions = [];
        this.selectedServices = [];
        this.selectedIndustries = [];

        res["result"].map((a) =>
          a.specialityTypeId == 2
            ? ((a.action = 3),
              this.selectedFunctions.push(a),
              this.saveFunctions.push(a))
            : a.specialityTypeId == 3
              ? ((a.action = 3),
                this.selectedServices.push(a),
                this.saveServices.push(a))
              : ((a.action = 3),
                this.selectedIndustries.push(a),
                this.saveIndustries.push(a))
        );
        (this.showMessageStripe = true), (this.loader = false);
        // this.GetSpecialities(res['result'][0]['personId'])
      });
    // console.log(JSON.stringify([...this.saveServices, ...this.saveFunctions, ...this.saveIndustries]))
  }

  // GET SPECIALITIES
  GetSpecialities(personId) {
    this.commonApi
      .getSpecialities(personId)
      .subscribe((res) =>
        res["result"].map((a) =>
          a.specialityTypeId == 2
            ? ((a.action = 3),
              this.selectedFunctions.push(a),
              this.saveFunctions.push(a))
            : a.specialityTypeId == 3
              ? ((a.action = 3),
                this.selectedServices.push(a),
                this.saveServices.push(a))
              : ((a.action = 3),
                this.selectedIndustries.push(a),
                this.saveIndustries.push(a))
        )
      );
  }

  //  SAVE BIOGRAPHY
  BiographySubmit(data, type) {
    this.loader = true;
    this.tabMessage = this.getTabMessage(type);
    this.commonApi.addBiography(data).subscribe((res) => {
      this.personDetailsForm.patchValue({
        bioData: res["result"]["bioData"],
      });
      window.scrollTo(0, 0),
        (this.showMessageStripe = true),
        (this.loader = false);
    });
  }

  showBeSelected(id) {
    return id == this.personDetailsForm.value.recordStatus ? true : null;
  }
  salutationSelected(id) {
    return id == this.personDetailsForm.value.salutation ? true : null;
  }
  showbedisabled() {
    return !this.reqParamsId || this.userRole == "Consultant" ? "" : null;
  }

  updateRecord(id) {
    this.personDetailsForm.patchValue({ recordStatus: Number(id) });
  }

  getTabMessage(type) {
    switch (type) {
      case "person":
        return "Person details ";
      case "speciality":
        return "Specialties ";
      case "biography":
        return "Biography ";
      case "references":
        return "References  ";
      case "company":
        return "Company details  ";
      case "Employment Details":
        return "Employment Details ";
      case "notes":
        return "Notes ";
      case "review":
        return "Review ";
      case "user":
        return "User Details ";
      default:
        return "Consultant details ";
    }
  }

  getCompanyByPerson(personId: any) {
    this.apiCallService
      .getPersonDetailsById(this.apiconfig.getCompany, personId)
      .subscribe(
        (res) => {
          this.selectedCompany = res["result"];
        },
        (err) => { }
      );
  }
  getTitleByPerson(personId: any) {
    this.apiCallService
      .getPersonDetailsById(this.apiconfig.getTitle, personId)
      .subscribe(
        (res) => {
          this.selectedTitle = res["result"];
        },
        (err) => { }
      );
  }

  sortCompany(data) {
    return data.sort((a, b) => {
      return a.companyName > b.companyName && a.address.cityName > b.address.cityName
    })
  }

  // GET COMPANY DATA
  getCompanyData(event) {
    let key = event.target.value;
    this.searching = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.commonApi.getCompanyData(key).subscribe((res) => {
        (this.companyDropdownData = this.sortCompany(res["result"])), (this.searching = false);
      });
    }, 600);
  }

  // SELECT COMPANY
  selectCompany(data) {
    this.selectedCompany = data;
    this.companyDropdownData = [];
    this.personDetailsForm.patchValue({
      companyId: data.id,
      companyAddressId: data.companyAddressId,
      isDiversity: data.isDiversity
    });
  }

  // SELECT COMPANY
  selectCompanyRef(data) {
    // this.selectedCompanyRef = data;
    this.companyDropdownData = [];
    this.referencesForm.patchValue({
      refCompany: data.id,
      refCompanyName: data.companyName,
    });
  }

  // GET TITLE DATA
  getTitleData(event) {
    let key = event.target.value;
    this.searchingTitle = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.commonApi.searchTitle(15, key).subscribe((res) => {
        (this.titleDropdownData = res["result"]), (this.searchingTitle = false);
      });
    }, 600);
  }

  // getTitle(param){
  //     this.commonApi.searchTitle(15, key).subscribe(res => {this.titleDropdownData = res['result'], this.searchingTitle = false});
  // }

  // SELECT COMPANY
  selectTitle(data) {
    this.selectedTitle = data;
    this.titleDropdownData = [];
    this.personDetailsForm.patchValue({
      title: data.id,
    });
  }
  selectTitleEmp(data) {
    this.selectedTitleEmp = data;
    this.titleDropdownData = [];
    this.empdetailsForm.patchValue({
      titleId: data.id,
      titleName: data.baseKeyValueName,
    });
  }

  SetAllSpeacialities(type, method) {
    switch (type) {
      case this.services_name:
        for (let i = 0; i < this.selectedServices.length; i++) {
          this.selectedServices[i].wgawInd = !this.selectedServices[i].wgawInd;
          this.saveServices[i].wgawInd = method == "approve" ? true : false;
        }
        break;
      case this.functions_name:
        for (let i = 0; i < this.selectedFunctions.length; i++) {
          this.selectedFunctions[i].wgawInd =
            !this.selectedFunctions[i].wgawInd;
          this.saveFunctions[i].wgawInd = method == "approve" ? true : false;
        }
        break;
      case this.inductries_name:
        for (let i = 0; i < this.selectedIndustries.length; i++) {
          this.selectedIndustries[i].wgawInd =
            !this.selectedIndustries[i].wgawInd;
          this.saveIndustries[i].wgawInd = method == "approve" ? true : false;
        }
        break;
    }
  }

  SetOneSpeciality(type, s) {
    let obj = null;
    switch (type) {
      case this.services_name:
        obj = this.selectedServices.find(
          (a) => a.specialityId == s.specialityId
        );
        obj.wgawInd = !obj.wgawInd;
        break;
      case this.functions_name:
        obj = this.selectedFunctions.find(
          (a) => a.specialityId == s.specialityId
        );
        obj.wgawInd = !obj.wgawInd;
        break;
      case this.inductries_name:
        obj = this.selectedIndustries.find(
          (a) => a.specialityId == s.specialityId
        );
        obj.wgawInd = !obj.wgawInd;
        break;
    }
  }

  editReference(data) {
    this.refModalOpen = true;
    this.refEditMode = true;
    this.referencesForm.patchValue(data);
  }

  AddNewEmp() {
    this.empModalOpen = true;
    this.empEditMode = false;
    this.selectedTitleEmp = null;
    this.empdetailsForm.patchValue({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 2,
      personId: Number(this.reqParamsId),
      employerName: "",
      titleId: 0,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      employmentOrder: 0,
      titleName: "",
      emplDuration: "",
      uniqueId: new Date().getTime(),
    });
  }

  OpenEmpModal(data) {
    this.empModalOpen = true;
    this.empEditMode = true;
    this.selectedTitleEmp = data.titleName;
    this.empdetailsForm.patchValue(data);
  }

  AddEmploymentDetails(data) {
    data.employmentOrder = Number(data.employmentOrder);
    this.empdetailsForm.patchValue({
      employerName: "",
      titleId: 0,
      startDate: new Date(data.startDate).toISOString(),
      endDate: new Date(data.endDate).toISOString(),
      employmentOrder: 0,
      titleName: "",
      emplDuration: "",
      uniqueId: new Date().getTime(),
    });
    this.selectedTitleEmp = null;
    const arr = this.empDataSource.data;

    let index1 = arr.findIndex((a) => a.uniqueId == data.uniqueId);
    let index2 = arr.findIndex((a) => a.id == data.id);
    if (index1 != -1) {
      arr.splice(index1, 1, data);
    } else if (index2 != -1 && data.id != 0) {
      arr.splice(index2, 1, data);
    } else {
      arr.push(data);
    }
    this.empDataSource.data = arr.sort(
      (a, b) => a.employmentOrder - b.employmentOrder
    );
    this.closeEmpModal();
  }

  SaveEmploymentDetails() {
    this.loader = true;
    this.tabMessage = this.getTabMessage("Employment Details");
    this.showMessageStripe = true;
    let arr = this.empDataSource.data;
    arr.map((a) => delete a.uniqueId);
    this.commonApi.addEmploymentDetails(arr).subscribe((res) => {
      (this.loader = false), this.GetEmploymentDetails(this.reqParamsId);
      window.scrollTo(0, 0),
        setTimeout(() => {
          this.showMessageStripe = false;
        }, 4000);
    });
  }

  SaveReferences() {
    this.loader = true;
    this.tabMessage = this.getTabMessage("references");
    this.showMessageStripe = true;
    this.commonApi.addReferences(this.refDataSource.data).subscribe((res) => {
      (this.loader = false),
        window.scrollTo(0, 0),
        setTimeout(() => {
          this.showMessageStripe = false;
        }, 4000);
    });
  }

  GetReviewsData() {
    this.commonApi
      .getReviewByPerson(
        this.reqParamsId,
        Number(localStorage.getItem("LoggedInUser"))
      )
      .subscribe((res) => {
        this.reviewsDataSource.data = [];
        let data = res["result"];
        const arr = this.reviewsDataSource.data;
        arr.push(...data);
        this.reviewsDataSource.data = arr;
      });
  }

  GetEmploymentDetails(id) {
    this.commonApi.getEmploymentDetails(id).subscribe((res) => {
      let data = res["result"];
      for (let i = 0; i < data.length; i++) {
        data[i].action = 3;
      }
      setTimeout(() => {
        this.empDataSource.data = [];
        const arr = this.empDataSource.data;
        arr.push(...data);
        this.empDataSource.data = arr.sort(
          (a, b) => a.employmentOrder - b.employmentOrder
        );
      }, 500);
    });
  }

  GetReferences(id) {
    this.commonApi.getReferences(id).subscribe((res) => {
      let data = res["result"];
      for (let i = 0; i < data.length; i++) {
        data[i].action = 3;
      }
      setTimeout(() => {
        this.refDataSource.data = [];
        const arr = this.refDataSource.data;
        arr.push(...data);
        this.refDataSource.data = arr;
      }, 500);
    });
  }

  AddReferences(data) {
    // data.refCompany= this.selectedCompanyRef.id,
    // data.refCompanyName= this.selectedCompanyRef.companyName
    const arr = this.refDataSource.data;
    arr.push(data);
    this.refDataSource.data = arr;
    this.refModalOpen = false;
    this.referencesForm.reset();
  }

  closeRefModal() {
    this.referencesForm.reset();
    this.refModalOpen = false;
  }
  closeEmpModal() {
    this.empModalOpen = false;
  }

  openSideModal(type) {
    this.sideModal = false;
    switch (type) {
      case this.services_name:
        this.sideModalData["heading"] = "Services";
        this.sideModalData["checkData"] = this.servicesCheckboxes;
        this.sideModalData["type"] = this.services_name;
        this.sideModal = true;
        break;

      case this.functions_name:
        this.sideModalData["heading"] = "Functions";
        this.sideModalData["checkData"] = this.functionsCheckboxes;
        this.sideModalData["type"] = this.functions_name;
        this.sideModal = true;

        break;

      case this.inductries_name:
        this.sideModalData["heading"] = "Industries";
        this.sideModalData["checkData"] = this.industriesCheckboxes;
        this.sideModalData["type"] = this.inductries_name;
        this.sideModal = true;
        break;
    }
  }

  removeFromList(val, type) {
    let i = 0,
      j = 0;
    let index;
    if (!this.sideModal) {
      switch (type) {
        case this.services_name:
          this.saveServices.find(
            (x) => x.specialityId === val.specialityId
          ).action = 4;
          j = this.selectedServices.findIndex(
            (x) => x.specialityId === val.specialityId
          );
          index = this.saveServices.findIndex((el) =>
            el.id !== 0 ? el.id === val.id : el.uniqueId === val.uniqueId
          );
          this.saveServices[index].isActive = false;
          this.selectedServices[j].isActive = false;
          // this.saveServices.splice(index, 1);
          // this.selectedServices.splice(j, 1);
          break;

        case this.functions_name:
          this.saveFunctions.find(
            (x) => x.specialityId === val.specialityId
          ).action = 4;
          j = this.selectedFunctions.findIndex(
            (x) => x.specialityId === val.specialityId
          );
          // this.saveFunctions.splice(j, 1);
          index = this.saveFunctions.findIndex((el) =>
            el.id !== 0 ? el.id === val.id : el.uniqueId === val.uniqueId
          );
          // this.saveFunctions.splice(index, 1);
          // this.selectedFunctions.splice(j, 1);
          this.saveFunctions[index].isActive = false;
          this.selectedFunctions[j].isActive = false;
          break;

        case this.inductries_name:
          this.saveIndustries.find(
            (x) => x.specialityId === val.specialityId
          ).action = 4;
          j = this.selectedIndustries.findIndex(
            (x) => x.specialityId === val.specialityId
          );
          // this.saveIndustries.splice(j, 1);
          index = this.saveIndustries.findIndex((el) =>
            el.id !== 0 ? el.id === val.id : el.uniqueId === val.uniqueId
          );
          // this.saveIndustries.splice(index, 1);
          // this.selectedIndustries.splice(j, 1);
          this.saveIndustries[index].isActive = false;
          this.selectedIndustries[j].isActive = false;
          break;
      }
    }
  }

  getCount(data) {
    return data.filter((el) => el.isActive).length;
  }

  uncheckOneFromList(val, type, prop) {
    let i = null,
      j = null;
    if (!this.sideModal) {
      switch (type) {
        case this.services_name:
          i = this.saveServices.find(
            (x) => x.specialityId === val.specialityId
          );
          // j = this.selectedServices.find(x => x.specialityId === val.specialityId);
          if (prop == "hasInd") {
            i.hasInd = !i.hasInd;
            // j.hasInd = !j.hasInd;
          } else {
            i.wgawInd = !i.wgawInd;
            // j.wgawInd = !j.wgawInd;
          }
          break;
        case this.functions_name:
          i = this.saveFunctions.find(
            (x) => x.specialityId === val.specialityId
          );
          if (prop == "hasInd") {
            i.hasInd = !i.hasInd;
          } else {
            i.wgawInd = !i.wgawInd;
          }
          // j = this.selectedFunctions.find(x => x.specialityId === val.specialityId);
          // if(prop == 'hasInd'){
          //   j.hasInd = !j.hasInd;
          // }else{
          //   j.wgawInd = !j.wgawInd;
          // }

          // j = this.selectedFunctions.findIndex(x => x.specialityId === val.specialityId);
          // this.saveFunctions.splice(j, 1);
          // this.selectedFunctions.splice(j, 1);
          break;

        case this.inductries_name:
          i = this.saveIndustries.find(
            (x) => x.specialityId === val.specialityId
          );
          if (prop == "hasInd") {
            i.hasInd = !i.hasInd;
          } else {
            i.wgawInd = !i.wgawInd;
          }

          // j = this.selectedIndustries.find(x => x.specialityId === val.specialityId);
          // if(prop == 'hasInd'){
          //   j.hasInd = !j.hasInd;
          // }else{
          //   j.wgawInd = !j.wgawInd;
          // }
          // j = this.selectedIndustries.findIndex(x => x.specialityId === val.specialityId);
          // this.saveIndustries.splice(j, 1);
          // this.selectedIndustries.splice(j, 1);
          break;
      }
    }
  }

  ApproveCheckbox(val, type) {
    let check1 = null;
    switch (type) {
      case this.services_name:
        check1 = this.selectedServices.find(
          (x) => x.specialityId === val.specialityId
        );
        check1.wgawInd = !check1.wgawInd;
        this.saveServices = [...this.selectedServices];
        break;

      case this.functions_name:
        check1 = this.saveFunctions.find(
          (x) => x.specialityId === val.specialityId
        );
        check1.wgawInd = !check1.wgawInd;
        break;

      case this.inductries_name:
        check1 = this.saveIndustries.find(
          (x) => x.specialityId === val.specialityId
        );
        check1.wgawInd = !check1.wgawInd;
        break;
    }
    console.log(this.saveServices);
    console.log(this.selectedServices);
  }

  storeCheckValue(val, type) {
    this.specialitiesForm.patchValue({
      specialityTypeId: val.baseKeyId,
      specialityId: val.id,
      specialityName: val.baseKeyValueName,
      action: 2,
      uniqueId: new Date().getTime(),
    });
    if (this.userRole == "Consultant") {
      this.specialitiesForm.patchValue({
        hasInd: true,
        wgawInd: false,
      });
    }
    switch (type) {
      case this.services_name:
        if (
          this.saveServices.filter(
            (a) =>
              a.specialityId == this.specialitiesForm.value.specialityId &&
              a.specialityTypeId == this.specialitiesForm.value.specialityTypeId
          ).length <= 0
        ) {
          this.saveServices.push(this.specialitiesForm.value);
        } else {
          let index = this.saveServices.findIndex(
            (x) =>
              x.specialityId === this.specialitiesForm.value.specialityId &&
              x.specialityTypeId == this.specialitiesForm.value.specialityTypeId
          );
          setTimeout(() => {
            this.saveServices[index].isActive =
              !this.saveServices[index].isActive;
            this.saveServices[index].action = 3;
          }, 0);
        }
        break;

      case this.functions_name:
        if (
          this.saveFunctions.filter(
            (a) =>
              a.specialityId == this.specialitiesForm.value.specialityId &&
              a.specialityTypeId == this.specialitiesForm.value.specialityTypeId
          ).length <= 0
        ) {
          this.saveFunctions.push(this.specialitiesForm.value);
        } else {
          let index = this.saveFunctions.findIndex(
            (x) =>
              x.specialityId === this.specialitiesForm.value.specialityId &&
              x.specialityTypeId == this.specialitiesForm.value.specialityTypeId
          );
          setTimeout(() => {
            this.saveFunctions[index].isActive =
              !this.saveFunctions[index].isActive;
            this.saveFunctions[index].action = 3;
          }, 0);
        }
        break;

      case this.inductries_name:
        if (
          this.saveIndustries.filter(
            (a) =>
              a.specialityId == this.specialitiesForm.value.specialityId &&
              a.specialityTypeId == this.specialitiesForm.value.specialityTypeId
          ).length <= 0
        ) {
          this.saveIndustries.push(this.specialitiesForm.value);
        } else {
          let index = this.saveIndustries.findIndex(
            (x) =>
              x.specialityId === this.specialitiesForm.value.specialityId &&
              x.specialityTypeId == this.specialitiesForm.value.specialityTypeId
          );
          setTimeout(() => {
            this.saveIndustries[index].isActive =
              !this.saveIndustries[index].isActive;
            this.saveIndustries[index].action = 3;
          }, 0);
        }
        break;
    }
  }

  pushSelectedChecks(type) {
    this.sideModal = false;
    switch (type) {
      case this.services_name:
        this.selectedServices = [...this.saveServices];
        this.sideModal = false;
        break;

      case this.functions_name:
        this.selectedFunctions = [...this.saveFunctions];
        this.sideModal = false;
        break;

      case this.inductries_name:
        this.selectedIndustries = [...this.saveIndustries];
        this.sideModal = false;
        break;
    }
  }

  shouldBeCheched(val, type) {
    let data;
    switch (type) {
      case this.services_name:
        data = this.selectedServices.find((a) => a.specialityId == val.id);
        return data
          ? data.isActive && (this.isConsultant ? data.hasInd : data.wgawInd)
          : false;

      case this.functions_name:
        data = this.selectedFunctions.find((a) => a.specialityId == val.id);
        return data
          ? data.isActive && (this.isConsultant ? data.hasInd : data.wgawInd)
          : false;

      case this.inductries_name:
        data = this.selectedIndustries.find((a) => a.specialityId == val.id);
        return data
          ? data.isActive && (this.isConsultant ? data.hasInd : data.wgawInd)
          : false;
    }
  }

  getSelectedCount(type) {
    switch (type) {
      case this.services_name:
        // console.log(this.saveServices);
        return this.saveServices.filter(
          (a) => a.isActive && (this.isConsultant ? a.hasInd : a.wgawInd)
        ).length;

      case this.functions_name:
        return this.saveFunctions.filter(
          (a) => a.isActive && (this.isConsultant ? a.hasInd : a.wgawInd)
        ).length;

      case this.inductries_name:
        return this.saveIndustries.filter(
          (a) => a.isActive && (this.isConsultant ? a.hasInd : a.wgawInd)
        ).length;
    }
  }

  shouldShowAll(type) {
    switch (type) {
      case this.services_name:
        return this.saveServices.length > 0;

      case this.functions_name:
        return this.saveFunctions.length > 0;

      case this.inductries_name:
        return this.saveIndustries.length > 0;
    }
  }

  cancel(KEY: string) {
    this.EXPANDED[KEY] = false;
  }

  shouldBeDisabled() {
    return this.reqParamsId ? false : true;
  }

  getConvertedDate(date) {
    let d = date ? new Date(date) : new Date();
    let tmp = d.toDateString().split(" ");
    let afterDate = tmp[2] + " " + tmp[1] + ", " + tmp[3];
    let time = d.toLocaleTimeString().split(":");
    let afterTime = time[0] + ":" + time[1];
    return afterDate + " " + afterTime;
  }

  getRatingCount(rating, rateNum) {
    let rate = rating.personReviewDetails.find((a) => a.reviewQnsId == rateNum);
    return (rate && rate.reviewAns) || 0;
  }

  shouldContact(rating) {
    let rate = rating.personReviewDetails.find((a) => a.reviewQnsId == 4758);
    return (rate && (rate.reviewAns == 1 ? "Yes" : "No")) || "No";
  }

  clearAllSpeacialities(type) {
    let data = [];
    if (type == "service") {
      for (let i = 0; i < this.selectedServices.length; i++) {
        this.selectedServices[i].action = 4;
        data.push(this.selectedServices[i]);
      }
    }
    setTimeout(() => {
      this.selectedServices = [];
      this.commonApi.updateSpecialities(data).subscribe((res) => {
        console.log(res);
      });
    }, 500);
  }

  specialityNameHeading(str) {
    switch (str) {
      case "Industries":
        return "Industry";
      case "Functions":
        return "Function";
      case "Services":
        return "Service";
      default:
        return "";
    }
  }

  openSpecialityAddModal(action, type) {
    if (action == "add") {
      this.sideModal = false;
      this.sidsAddCodeModalOpen = true;
      this.addBaseValueForm.patchValue({
        baseKeyId: type == "Services" ? 3 : type == "Functions" ? 2 : 4,
      });
    } else {
      this.sidsAddCodeModalOpen = false;
      this.sideModal = true;
    }
  }

  loadFile(event) {
    var image = document.getElementById("output");
    image["src"] = URL.createObjectURL(event.target.files[0]);
    this.imgURL = URL.createObjectURL(event.target.files[0]);
    this.personDetailsForm.patchValue({ photo: event.target.files[0] });
    this.imgPresent = true;
  }

  closeRateModal() {
    this.rateModal = false;
    this.star1 = 0;
    this.star2 = 0;
    this.star3 = 0;
    this.displayValuesForm.reset();
    this.tempReviewArr = [];
    this.showMore = false;
  }
  SaveReviews(type) {
    this.ratingsForm.patchValue({
      reviewStatusId: type == "save" ? 4754 : 4751,
    });
    // this.reviewToastMsg = type == 'save' ? 'saved' : 'submitted';
    this.tabMessage = this.getTabMessage("review");
    this.loader = true;
    this.ratingsForm.value.personReviewDetails = [];
    this.ratingsForm.value.personReviewDetails.push(...this.tempReviewArr);
    // console.log(this.ratingsForm.value)
    this.commonApi.addReview(this.ratingsForm.value).subscribe((res) => {
      this.loader = false;
      this.rateModal = false;
      window.scrollTo(0, 0);
      this.GetReviewsData();
      if (res["status"] == 2 && res["message"].includes("Already")) {
        this.showErrorToast = true;
      } else {
        this.showMessageStripe = true;
      }
      setTimeout(() => {
        this.showMessageStripe = false;
        this.showErrorToast = false;
      }, 4000);
      this.displayValuesForm.reset();
      this.star1 = 0;
      this.star2 = 0;
      this.star3 = 0;
      this.showAll = false;
      this.reviewData = [];
      this.tempReviewArr = [];
    });
  }

  getISODate(date) {
    return new Date(date).toISOString();
  }

  SetRating(id, i, loop) {
    let review = {
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      personReviewId: 0,
      reviewQnsId: id,
      reviewAns: String(i),
      reviewQns: "",
    };
    if (this.tempReviewArr.findIndex((a) => a.reviewQnsId == id) == -1) {
      this.tempReviewArr.push(review);
    } else {
      this.tempReviewArr.find((a) => a.reviewQnsId == id).reviewAns = String(i);
    }
    // console.log(this.tempReviewArr)

    if (loop == "one") {
      this.star1 = i;
    }
    if (loop == "two") {
      this.star2 = i;
    }
    if (loop == "three") {
      this.star3 = i;
    }
  }

  ShowRateModal() {
    this.loader = true;
    this.apiCallService
      .getById("api/Consultant/GetConsultant", this.personId)
      .subscribe((response) => {
        this.previewDatails = response["result"];
        console.log("this.previewDatails", this.previewDatails);
        this.rateModal = true;
        this.loader = false;
        this.ratingsForm.patchValue({
          personId: this.previewDatails.id,
        });
      });
  }

  OpenPermissionModal(action) {
    this.permissionModal = action;
    let rec = this.permissionDataSource.data[0];
    if (rec.roleName !== "-") {
      this.addPermissionForm.patchValue({
        roleId: rec.roleId,
        roleName: rec.roleName,
        personID: Number(this.reqParamsId),
        personEmail:
          this.personDetailsForm.value.contactDetails.email || rec.personEmail,
        registrationLink: `${this.document.location.origin}/signup/${this.reqParamsId}`,
        lastUpdateByName: rec.lastUpdateByName || "",
        isActive: rec.isActive,
        createdBy: rec.createdBy,
        createdOn: rec.createdOn,
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        actionID: 2,
      });
    }
  }

  isBtnDisabled() {
    return this.personDetailsForm.value.recordStatus != 1488;
  }

  disableInviteButton() {
    return this.reqParamsId ? (this.reqParamsId > 0 ? null : true) : true;
  }

  getEncType() {
    return this.userRole == "User"
      ? this.reqParamsId
        ? "application/json"
        : "multipart/form-data"
      : "multipart/form-data";
  }

  disableCheckbox() {
    return this.userRole == "Admin" || this.userRole == "User";
  }

  SetPermissionData() {
    this.permissionDataSource.data = [
      { roleName: "-", lastUpdateBy: "-", lastUpdateOn: "-" },
    ];
  }

  getAllPermissions() {
    this.commonApi.getAllPermissions().subscribe((res) => {
      this.permissionsData = res["result"].filter((el) => el.roleId != 4);
    });
  }

  showPermissionSelected(str) {
    return str === localStorage.getItem("LoginRole") ? true : null;
  }

  getPermisionById(id) {
    this.commonApi.getPermissionsByPersonId(id).subscribe((res) => {
      const { result }: any = res;
      this.permissionDataSource.data =
        result.length > 0
          ? result
          : [{ roleName: "-", lastUpdateBy: "-", lastUpdateOn: "-" }];
    });
  }

  SavePermissions(data) {
    this.commonApi.updatePermission(data).subscribe((res) => {
      this.permissionModal = false;
      this.openDialog();
      this.getPermisionById(this.reqParamsId);
    });
  }
  closeMoreDetails() {
    this.router.navigate([`user/suggest-consultant`]);
  }
}
@Component({
  selector: "popup-dialouge",
  styleUrls: ["./popup-dialouge.scss"],
  templateUrl: "popup-dialouge.html",
})
export class PopupDialouge { }
