import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCallService } from 'src/app/services/api-call.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { SharedService } from 'src/app/services/shared.service';

export interface Idata {
  firstName: string;
  lastName: string;
  webSite: string;
  isExecsmartMemeber: boolean;
  memberStatus: string;
}

const ELEMENT_DATA: Idata[] = [];

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent  {

  displayedColumns: string[] = ['firstName', 'lastName', 'webSite', 'isExecsmartMemeber', 'memberStatus'];
  panelOpenState: boolean = false;
  dataSource: MatTableDataSource<Idata>;
  userList: Idata[] = [];
  pageData: any;
  totalRecordCount: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;
  loader: boolean;
  uploadcount:any;
  totalcount: any;

  constructor(
    public commonApi: CommonApiService,
    private sharedService: SharedService,
    private apiCallService: ApiCallService,
  ) {
    this.dataSource = new MatTableDataSource(this.userList);
  }

  onFileSelected(event: any) {
    this.loader = true
    let fileUpload = event.target.files[0];
    let data = new FormData();
    data.append("file", fileUpload);
    this.commonApi.uploadBulkUsers(data).subscribe((res: any) => {
      if (res && res.result) {
        this.userList = res.result;
        this.loader = false;
        if (this.fileUploader) {
          this.fileUploader.nativeElement.value = null;
        }
        this.dataSource = new MatTableDataSource(this.userList);
        this.dataSource.paginator = this.paginator;
        this.sharedService.showToast("File Uploaded Successfully!");
        const count = this.dataSource.data.reduce((acc, curr) => {
          return !curr.isExecsmartMemeber ? acc + 1 : acc;
        }, 0);
        console.log('count:',count);
        this.uploadcount=count;
        this.totalcount=this.dataSource.data.length;
      }
    });
  }
  saveUploads() {
    this.commonApi.saveBulkUsers(this.dataSource.data).subscribe((res: any) => {
      this.sharedService.showToast("Out of"+" "+this.totalcount+" "+"Users"+" "+this.uploadcount+" "+"Users Published Successfully!");
      this.dataSource.data = [];
    })
  }

  clearRows() {
    this.dataSource.data = [];
  }

}
