import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommonApiService } from "./../../services/common-api.service";

@Component({
  selector: "app-review-ratings-view",
  templateUrl: "./review-ratings-view.component.html",
  styleUrls: ["./review-ratings-view.component.scss"],
})
export class ReviewRatingsViewComponent implements OnInit {
  updateReviewForm: FormGroup;
  step = 0;
  loader = true;
  reviewId: any;
  reviewData = [];
  arr = new Array(5);
  userRole = localStorage.getItem("LoginRole");

  specialitiesData = [];
  industriesData = [];
  userId = localStorage.getItem("LoggedInUser");
  isUserApproved = true;
  isUserRejected: Boolean = false;

  constructor(
    private location: Location,
    private form: FormBuilder,
    public commonApi: CommonApiService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
    this.reviewId = this.route.snapshot.paramMap.get("personId");
  }

  setStep(index: number) {
    this.step = index;
  }

  ngOnInit() {
    this.GetReviewData(this.reviewId);
    this.GetSpecialities();
    this.GetIndustries();
    this.updateReview();
  }

  GetSpecialities() {
    this.commonApi.getBaseData(2).subscribe((data) => {
      this.specialitiesData = data["result"];
    });
  }
  GetIndustries() {
    this.commonApi.getBaseData(4).subscribe((data) => {
      this.industriesData = data["result"];
    });
  }

  GetReviewData(id) {
    this.commonApi.getReview(id, this.userId).subscribe((res) => {
      let data = res["result"];
      this.reviewData = data;
      this.isUserApproved =
        data &&
        (data.personRecordStatus == 1487 || data.personRecordStatus == 1490)
          ? false
          : true;
      this.isUserRejected =
        data && (data.personRecordStatus == 1489 ? true : false);
      if (data) {
        this.updateReviewForm.patchValue({
          personId: data.personId,
          id: data.id,
          personReviewDetails: data.personReviewDetails,
          createdBy: data.createdBy,
          createdOn: data.createdOn,
          reviewStatusId: data.reviewStatusId,
          personInvitedById: data.personInvitedById,
          personReviewedById: data.personReviewedById,
          personReviewedByName: data.personReviewedByName,
        });
      }
    });
    this.loader = false;
  }

  updateReview() {
    this.updateReviewForm = this.form.group({
      id: 0,
      createdBy: "",
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      personId: 0,
      personInvitedById: 0,
      personReviewedById: 0,
      reviewStatusId: 0,
      comments: "",
      personReviewedByName: "",
      personReviewDetails: [],
    });
  }

  SubmitUpdatedReview(data) {
    this.loader = true;
    data.reviewStatusId = Number(data.reviewStatusId);
    this.commonApi.updateReview(data).subscribe((res) => {
      if (res["status"] == 1) {
        this._snackBar.open("Review updated successfully", "GOT IT", {
          duration: 4000,
        });
        this.router.navigate([
          this.userRole === "Admin"
            ? `admin/review-ratings`
            : `user/review-ratings`,
        ]);
      }
      this.loader = false;
      this.updateReviewForm.disable();
    });
  }

  cancel() {
    this.location.back();
    //   this.router.queryParams.subscribe((params) => {
    //     this.returnUrl = params.returnUrl;
    // });
  }

  shouldBeChecked(id) {
    return this.updateReviewForm.value.reviewStatusId == id ? true : false;
  }

  shouldBeDisabled() {
    return !this.isUserApproved;
  }

  getConvertedDate(date) {
    if (!date) return;
    let d = new Date(date);
    let tmp = d.toDateString().split(" ");
    let afterDate = tmp[2] + " " + tmp[1] + ", " + tmp[3];
    let time = d.toLocaleTimeString().split(":");
    let afterTime =
      time[0] + ":" + time[1] + " " + time[2] && time[2].split(" ")[1];
    return afterDate + " " + afterTime;
  }
}
