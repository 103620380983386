import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './home/login/login.component';
import { DefaultComponent } from './shared/default/default.component';
import { AuthGuard } from './services/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { SignupComponent } from './home/signup/signup.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      breadcrumb: 'Login Page'
    }
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      breadcrumb: 'SignUp Page'
    }
  },
  {
    path: 'signup/:personId',
    component: SignupComponent,
    data: {
      breadcrumb: 'SignUp Page'
    }
  },
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Dashboard'
    },
    children: [
      {
        path: 'admin',
        canActivateChild: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Admin'],
            redirectTo: "/login"
          },
        },      
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'user',
        canActivateChild: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['User'],
            redirectTo: "/login"
          },
        },         
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'consultant',
        canActivateChild: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Consultant'],
            redirectTo: "/login"
          },
        },
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
          },
          {
            path: 'dashboard',
            component: DashboardComponent,
            data: {
              breadcrumb: 'Dashboard'
            }
          },
        ] 
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [],
  imports: [CommonModule,RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
