import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonApiService } from "./../../services/common-api.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiCallService } from "src/app/services/api-call.service";
import { SharedService } from './../../services/shared.service';
import { MatSort, Sort } from "@angular/material/sort";

let USER_DATA = [];

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit {
  userActivityForm: FormGroup;
  companyForm: FormGroup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  companyDataSource = new MatTableDataSource(USER_DATA);
  loader = false;
  activityData = [];
  selectedUser = null;
  searching = false;
  userData = [];
  timer = null;
  selectedCompany = null;
  companyModalOpen = false;
  companyDropdownData = [];
  editMode = false;
  sortedData: any = [];

  cityData = [];
  stateData = [];
  countryData = [];

  constructor(
    private _snackBar: MatSnackBar,
    public apiCallService: ApiCallService,
    private sharedService: SharedService,
    private form: FormBuilder,
    public commonApi: CommonApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sortedData = this.companyDataSource.data.slice();
  }

  displayedColumns: string[] = [
    "company",
    "address",
    "city",
    "country",
    "state",
    "zipcode",
    "Consultants",
    "action",
  ];
  ngOnInit() {
    // this.GetActivityData();
    this.UserActivityForm();
    this.CompanyDetailsForm();
    this.SetCountryData();
    this.SetStateData();
    this.SetCityData();
  }

  // GetActivityData(){
  //   this.commonApi.getBaseData(22).subscribe((data) => {this.activityData = data['result']; this.loader = false;})
  // }

  UserActivityForm() {
    this.userActivityForm = this.form.group({
      specialityId: 0,
      companyId: 0,
      countryId: 0,
      name: "string",
      consultantTypeId: 0,
      regionId: 0,
      userId: 0,
      role: 0,
      gridType: 0,
      pageIndex: 0,
      pageSize: 0,
      activityTypeId: 0,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
    });
  }

  CompanyDetailsForm() {
    this.companyForm = this.form.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 2,
      companyName: "",
      consultants: "",
      aboutCompany: "",
      companyWebsiteUrl: "",
      phoneNumber1: "",
      phoneNumber2: "",
      addressId: 0,
      isDiversity: false,
      address: this.form.group({
        id: 0,
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 0,
        action: 2,
        line1: "",
        line2: "",
        line3: "",
        cityId: 0,
        stateId: 0,
        postalCode: "",
        country: null,
        addressType: 0,
      }),
    });
  }

  AddCompanyDetailsForm(data) {
    this.loader = true;
    if (this.editMode) {
      this.commonApi.updateCompany(data).subscribe((res) => {
        this.loader = false;
        this.companyModalOpen = false;
        this.editMode = false;
        this.getCompanyData();
        this.sharedService.showToast("Company updated successfully")
      });
    } else {
      this.commonApi.addCompany(data).subscribe((res) => {
        this.loader = false;
        this.companyModalOpen = false;
        this.editMode = false;
        this.getCompanyData();
        this.sharedService.showToast("Company added successfully");
      });
    }
  }

  SetCountry(event) {
    console.log(event.target.value);
    this.companyForm.patchValue({
      address: { country: Number(event.target.value) },
    });
  }
  SetCity(event) {
    console.log(event.target.value);
    this.companyForm.patchValue({
      address: { cityId: Number(event.target.value) },
    });
  }
  SetState(event) {
    console.log(event.target.value);
    this.companyForm.patchValue({
      address: { stateId: Number(event.target.value) },
    });
  }

  // getCompanyData(event){
  //   let key = event.target.value;
  //   this.searching = true;
  //   clearTimeout(this.timer);
  //   this.timer = setTimeout(() => {
  //     this.commonApi.getCompanyData(key).subscribe(res => {this.companyDropdownData = res['result'], this.searching = false});
  //   }, 600)
  // }
  getCompanyData() {
    let id = document.getElementById("searchinp");
    let key = "";
    if (id) {
      key = id["value"];
      this.loader = true;
      this.commonApi.getCompanyData(key).subscribe((res) => {
        this.companyDataSource.data = [];
        let data = res["result"];
        const arr = this.companyDataSource.data;
        arr.push(...data);
        this.sortedData = arr;
        this.companyDataSource.data = arr;
        this.loader = false;
        if (data.length == 0) {
          this.sharedService.showToast("No Company Found");
        }
      });
    }
  }

  deleteCompany(companyId: number): void {
    this.loader = true;
    this.commonApi.deleteCompany(companyId).subscribe((res) => {
      const { result }: any = res;
      if (result) {
        this.sharedService.showToast("Company deleted successfully.");
      } else {
        this.sharedService.showToast("Failed to delete company.");
      }
      this.loader = false;
    }, err => {
      this.loader = false;
      this.sharedService.showToast('Failed to delete company')
    })
  }
  deleteCompanyAddress(companyAddressId: number): void {
    this.loader = true;
    this.commonApi.deleteCompanyAddress(companyAddressId).subscribe((res) => {
      const { result }: any = res;
      if (result) {
        this.sharedService.showToast("Company address deleted successfully.");
      } else {
        this.sharedService.showToast("Failed to delete company address.");
      }
      this.loader = false;
    }, err => {
      this.loader = false;
      this.sharedService.showToast('Failed to delete company address')
    })
  }

  // SELECT COMPANY
  selectCompany(data) {
    this.loader = true;
    this.selectedCompany = data;
    this.companyModalOpen = true;
    this.editMode = true;

    this.companyForm.patchValue({
      id: data.id,
      createdBy: data.createdBy,
      createdOn: data.createdOn,
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: data.isActive,
      status: data.status,
      action: 0,
      companyName: data.companyName || "",
      aboutCompany: data.aboutCompany || "",
      companyWebsiteUrl: data.companyWebsiteUrl || "",
      phoneNumber1: data.phoneNumber1 || "",
      phoneNumber2: data.phoneNumber2 || "",
      addressId: data.addressId || 0,
      companyAddressId: 0,
      consultants: data.consultants || "",
      isDiversity: false,
      address: {
        id: data.address.id,
        createdBy: data.address.createdBy,
        createdOn: data.address.createdOn,
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: data.address.isActive,
        status: data.address.status,
        action: data.address.action,
        line1: data.address.line1,
        line2: data.address.line2,
        line3: data.address.line3,
        cityId: data.address.cityId,
        stateId: data.address.stateId,
        postalCode: data.address.postalCode,
        country: data.address.country,
        addressType: 0,
        countryName: data.address.countryName || "",
      },
    });

    // this.companyDataSource.data = [];
    // let arr = [];
    // arr.push(data);
    // this.companyDataSource.data = arr;
    // this.companyDropdownData = [];
    this.loader = false;
  }

  closeModal() {
    this.companyModalOpen = false;
    this.editMode = false;
    this.companyForm.patchValue({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      companyName: "",
      address: {
        id: 0,
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        line1: "",
        line2: "",
        line3: "",
        cityId: 0,
        stateId: 0,
        postalCode: "",
        country: 0,
      },
    });
  }

  getActivity(id) {
    return this.activityData.find((a) => a.id == 4787).baseKeyValueName || "";
  }

  SetCountryData() {
    this.commonApi.getBaseData(5).subscribe((data) => {
      this.countryData = data["result"].filter(el => el.isActive).sort((a, b) =>
        a.baseKeyValueName.localeCompare(b.baseKeyValueName)
      );
    });
  }

  SetStateData() {
    this.commonApi.getBaseData(7).subscribe((data) => {
      this.stateData = data["result"].filter(el => el.isActive).sort((a, b) =>
        a.baseKeyValueName.localeCompare(b.baseKeyValueName)
      );
    });
  }

  SetCityData() {
    this.commonApi.getBaseData(8).subscribe((data) => {
      this.cityData = data["result"].filter(el => el.isActive).sort((a, b) =>
        a.baseKeyValueName.localeCompare(b.baseKeyValueName)
      );
    });
  }

  ShowConsultants(data) {
    this.apiCallService.selectedCountry.next(0);
    this.router.navigate(["/admin/consultants/0"], {
      state: { company: { ...data } },
    });
  }

  getConvertedDate(date) {
    let d = new Date(date);
    let tmp = d.toDateString().split(" ");
    let afterDate = tmp[2] + " " + tmp[1] + ", " + tmp[3];
    let time = d.toLocaleTimeString().split(":");
    let afterTime = time[0] + ":" + time[1] + " " + time[2].split(" ")[1];
    return afterDate + " " + afterTime;
  }

  sortData(sort: Sort) {
    const data = this.companyDataSource.filteredData.slice();
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    } else {
      this.sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        switch (sort.active) {
          case "companyName":
            return compare(a.companyName, b.companyName, isAsc);
          case "address":
            return compare(a.address.line1, b.address.line1, isAsc);
          case "city":
            return compare(a.address.cityName, b.address.cityName, isAsc);
          case "countryName":
            return compare(a.address.countryName, b.address.countryName, isAsc);
          case "stateName":
            return compare(a.address.stateName, b.address.stateName, isAsc);
          case "postalCode":
            return compare(a.address.postalCode, b.address.postalCode, isAsc);
          default:
            return 0;
        }
      });
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
