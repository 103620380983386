import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from './../../services/common-api.service';
import { ApiCallService } from 'src/app/services/api-call.service';



@Component({
  selector: 'app-compare-consultant',
  templateUrl: './compare-consultant.component.html',
  styleUrls: ['./compare-consultant.component.scss']
})
export class CompareConsultantComponent implements OnInit {

  displayedColumns: string[] = ['name', 'company', 'phone', 'email', 'comments'];
  displayedColumns2: string[] = ['company', 'title', 'completionDate'];
  displayedColumns3: string[] = ['reviewer', 'lastModified', 'trust', 'delivery', 'useAgain', 'contact'];
  displayedColumns4: string[] = ['employername', 'title', 'duration', 'employmentOrder'];

  EmploymentDetails = [];

 
  reqParams = [];
  usersData = [];
  
  userRole = localStorage.getItem("LoginRole");
  apiconfig:any;
  title:any;
  constructor(private form: FormBuilder, public commonApi: CommonApiService,private route: ActivatedRoute, private router: Router, public apiCallService: ApiCallService) {
  this.reqParams.push(this.route.snapshot.paramMap.get('personId1'), this.route.snapshot.paramMap.get('personId2'));
  
    this.apiconfig = this.apiCallService.apiendPoints.personDetails;
    this.route.data.subscribe(data => {
      this.title = data.title;
    });
  }

  step = 0;
  arr = new Array(5);
  sideModal = false;
  showNotes = false;
  notesModal = false;

  salutationData = [];
  personId = 0;
  recordStatusData = [];
  titleData = [];
  countryData = [];
  stateData = [];
  cityData = [];
  selectedCompany = null;
  selectedTitle = null;
  loader = true;

  // STORE ALL SPECIALITIES CHECKBOXES VALUES
  servicesCheckboxes = [];
  functionsCheckboxes = [];
  industriesCheckboxes = [];
  
  selectedServices = [];
  selectedFunctions = [];
  selectedIndustries = [];

  companyDropdownData = [];
  titleDropdownData = [];

  setStep(index: number) {
    this.step = index;
  }

  ngOnInit() {

      this.getTitleByPerson(this.reqParams[0]);

      this.SetSalutationData();
      this.SetRecordStatusData();
      this.SetCountryData();
      this.SetStateData();
      this.SetCityData();
      this.SetServicesData();
      this.SetFunctionsData();
      this.SetIndustriesData();
      this.GetUserDetails();
      
      setTimeout(() => {
        document.querySelectorAll('input').forEach(a => a.disabled = true);
        document.querySelectorAll('select').forEach(b => b.disabled = true);
        document.querySelectorAll('textarea').forEach(c => c.disabled = true);
        
        // SET SPECIALITY TO EQUAL HEIGHT
        let height1=[], height2=[], height3=[];
        document.querySelectorAll('.sp-service').forEach(a => height1.push(a["offsetHeight"]));
        document.querySelectorAll('.sp-function').forEach(a => height2.push(a["offsetHeight"]));
        document.querySelectorAll('.sp-industry').forEach(a => height3.push(a["offsetHeight"]));
    
        document.querySelectorAll('.sp-service').forEach(a => a['style']['height'] = Math.max(...height1)+"px");
        document.querySelectorAll('.sp-function').forEach(a => a['style']['height'] = Math.max(...height2)+"px");
        document.querySelectorAll('.sp-industry').forEach(a => a['style']['height'] = Math.max(...height3)+"px");
      }, 5000)
  }



  SetSalutationData(){
    this.commonApi.getBaseData(16).subscribe((data) => {this.salutationData = data['result'];})
  }
  SetServicesData(){
    this.commonApi.getBaseData(3).subscribe((data) => {this.servicesCheckboxes = data['result']})
  }
  SetFunctionsData(){
    this.commonApi.getBaseData(2).subscribe((data) => {this.functionsCheckboxes = data['result'];})
  }
  SetIndustriesData(){
    this.commonApi.getBaseData(4).subscribe((data) => {this.industriesCheckboxes = data['result'];})
  }

  SetRecordStatusData(){
    this.commonApi.getBaseData(9).subscribe((data) => {this.recordStatusData = data['result']; if(this.userRole=='User'){this.recordStatusData = this.recordStatusData.filter(a => a.id !=1488 && a.id != 1489)}})
  }
  // SetTitleData(){
  //   this.commonApi.getBaseData(15).subscribe((data) => {this.titleData = data['result'];})
  // }

  SetCountryData(){
    this.commonApi.getBaseData(5).subscribe((data) => {this.countryData = data['result'];})
  }
  
  SetStateData(){
    this.commonApi.getBaseData(7).subscribe((data) => {this.stateData = data['result'];})
  }
  
  SetCityData(){
    this.commonApi.getBaseData(8).subscribe((data) => {this.cityData = data['result'];})
  }


  GetUserDetails(){
    for(let i=0; i<this.reqParams.length; i++){
      this.commonApi.getConsultentDetails(this.reqParams[i]).subscribe(res => {
        this.usersData.push(res['result']);
        this.loader = false;
      })
    }
    // if(this.usersData.length>0){}
  }
  
  
  // setSalucation(e){
  //   this.personDetailsForm.patchValue({salutation: Number(e.target.value)})
  // }

 



  getTitleByPerson(personId:any){
    this.apiCallService.getPersonDetailsById(this.apiconfig.getTitle, personId).subscribe(
      res => console.log(res))
  }


  // getTitle(param){
  //     this.commonApi.searchTitle(15, key).subscribe(res => {this.titleDropdownData = res['result'], this.searchingTitle = false});
  // }

  
  getConvertedDate(date){
    let d = new Date(date);
    let tmp = d.toDateString().split(' ')
    let afterDate = tmp[2]+' '+tmp[1]+', '+tmp[3];
    let time = d.toLocaleTimeString().split(':')
    let afterTime = time[0]+":"+time[1]+" "+time[2].split(' ')[1]
    return afterDate +' '+ afterTime;
  }
  
  getRatingCount(rating, rateNum){
    let rate = rating.personReviewDetails && rating.personReviewDetails.find(a => a.reviewQnsId == rateNum);
    return rate && rate.reviewAns || 0;
  }

  shouldContact(rating){
      let rate = rating.personReviewDetails && rating.personReviewDetails.find(a => a.reviewQnsId == 4758);
      return rate && (rate.reviewAns == 1 ? 'Yes' : 'No') || ""
  }

  getSpeciality(speciality, id){
    return speciality.specialityTypeId == id ? speciality.specialityName : null;
  }
  showSpeciality(speciality, id){
    return speciality.specialityTypeId == id ? true : false;
  }

  getMiddlename(name){
    return (name && name != 'null') ? name : '';
  }
        

}

