import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonApiService } from './../../services/common-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallService } from './../../services/api-call.service';


export interface PeriodicElement {
  id: number,
  createdBy: string,
  createdOn: string,
  lastUpdateBy: string,
  lastUpdateOn: string,
  isActive: boolean,
  status: number,
  action: number,
  parentUserMessageId: number,
  fromPersonId: number,
  toPersonId: number,
  messageData: string,
  readInd: boolean,
  deletInd: number,
  messageBy: string
}

let MESSAGES_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MessageComponent implements OnInit {

  messageDataSource = new MatTableDataSource(MESSAGES_DATA);
  loader = true;
  messageCount = 0;
  expandedElement: PeriodicElement | null;

  constructor(private _snackBar: MatSnackBar, private apiCallService: ApiCallService, private form: FormBuilder, public commonApi: CommonApiService, private route: ActivatedRoute, private router: Router) { }

  displayedColumns: string[] = ['message', 'messageBy', 'receivedDate', 'action'];

  ngOnInit() {
    this.GetUserMessages(localStorage.getItem("LoggedInUser"));
    this.getMessageCount(localStorage.getItem("LoggedInUser"));
    this.fetchStatus();
  }

  getMessageCount(id) {
    this.commonApi.getUnreadMessageCount(id).subscribe(res => {
      this.messageCount = res['result'];
      this.apiCallService._setMessageCount(Number(res['result']))
      this.fetchStatus();
    })
  }

  fetchStatus = () => {
    this.apiCallService.getMessageCount().subscribe(res => {
      this.messageCount = res;
    });
  }

  AddAMessage(role, data, type) {
    if (role == 'Admin') {
      this.router.navigate([`admin/add-message`], { state: { data, type } });
    }
    if (role == 'User') {
      this.router.navigate([`user/add-message`], { state: { data, type } });
    }
  }


  GetUserMessages(id) {
    this.loader = true;
    this.commonApi.getUserMessages(id).subscribe(res => {

      // let data = res['result'].sort((a, b) => a.readInd - b.readInd);
      let data = this.combineMessages(res['result'])
      this.messageDataSource.data = [];
      const arr = this.messageDataSource.data;
      if (data) {
        arr.push(...data);
      }
      this.messageDataSource.data = arr.reverse();
      this.loader = false;
    })
  }

  getConvertedDate(date) {
    let d = new Date(date);
    let tmp = d.toDateString().split(' ')
    let afterDate = tmp[2] + ' ' + tmp[1] + ', ' + tmp[3];
    let time = d.toLocaleTimeString().split(':')
    let afterTime = time[0] + ":" + time[1]
    return afterDate + ' ' + afterTime;
  }

  combineMessages(arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i].replies = arr.filter(a => a.parentUserMessageId == arr[i].id)
    }
    return arr.filter(a => a.parentUserMessageId == 0);
  }

  getBackground(data) {
    return data.readInd || (data.replies.length > 0 && data.replies.filter(a => a.readInd))
  }

}

