import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/services/api-call.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  constructor(public apiCallService:ApiCallService, private permissionsService: NgxPermissionsService) { 
  }

  ngOnInit() {
  }

}
