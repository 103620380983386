import { Injectable } from '@angular/core';
import { getAPIEndpoint } from './env';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  // EXECSMART BASE API URL
  // public consultantApiBaseUrl = 'https://execsmart.azurewebsites.net/';
  // public consultantApiBaseUrl = 'https://localhost:44329/';
  public consultantApiBaseUrl = getAPIEndpoint();

  //EXT
  public ext = {
    'CoreData': {
      'AdminData': {
        'Getconsultant': 'api/Consultant/Getconsultant',
        'Getconsultants': 'api/Consultant/Getconsultants',
        'AddConsultant': 'api/Consultant/AddConsultant',
        'UpdateConsultant': 'api/Consultant/UpdateConsultant',
        'SearchConsultant': 'api/Consultant/SearchConsultant',
        'GetTitle': 'api/Base/GetTitles',
        'GetConsultantDetails': 'api/Consultant/GetConsultantDetails',
        'SubmitConsultant': 'api/Consultant/SubmitConsultant',
        'GetNonConsultants': 'api/Consultant/GetNonConsultants',
        'GetRecentSearchByPersonId': 'api/ConsultantHistory/GetRecentSearchByPersonId',
        'UploadBulkUsers': 'api/Consultant/UploadBulkUsers',
        'SaveBulkUsers' : 'api/Consultant/SaveBulkUsers'

      },
      'BaseKeyData': {
        'GetBaseValues': 'api/Base/GetBaseKeyValues',
        'GetBaseKeys': 'api/Base/GetBaseKeys',
        'AddBaseKeyValue': 'api/Base/AddBaseKeyValue',
        'UpdateBaseKeyValue': 'api/Base/UpdateBaseKeyValue',
        'DeleteBaseKeyValue': 'api/Base/DeleteBaseKeyValue'
      },
      'CompanyData': {
        'GetCompany': 'api/Company/GetCompanies',
        'AddCompany': 'api/Company/AddCompany',
        'UpdateCompany': 'api/Company/UpdateCompany',
        'GetCompaniesWithIDs': 'api/Company/GetCompaniesWithIDs',
        'DeleteCompany': 'api/Company/DeleteCompany',
        'DeleteCompanyAddress': 'api/Company/DeleteCompanyAddress'
      },
      'Specialities': {
        'AddSpecialities': 'api/Specalities/SaveSpecialities',
        'GetSpecialities': 'api/Specalities/GetSpecialities',
        'UpdateSpecialities': 'api/Specalities/UpdateSpecialities'
      },
      'Biography': {
        'SaveBiography': 'api/Consultant/SaveBiography'
      },
      'Employment': {
        'GetEmploymentDetails': 'api/Employment/GetEmploymentDetails',
        'SaveEmploymentDetails': 'api/Employment/SaveEmploymentDetails'
      },
      'Reference': {
        'GetReferences': 'api/Reference/GetReferences',
        'SaveReferences': 'api/Reference/SaveReferences'
      },
      'Reviews': {
        'AddReview': 'api/Review/AddReview',
        'GetReview': 'api/Review/GetReview',
        'GetReviewByPerson': 'api/Review/GetReviewsByPersonId',
        'UpdateReview': 'api/Review/UpdateReview'
      },
      'Notes': {
        'SaveNotes': 'api/Consultant/SaveNote',
        'GetNotes': 'api/Consultant/GetNotes',
        'GetNote': 'api/Consultant/GetNote'
      },
      'History': {
        'ShowConsultantHistory': 'api/ConsultantHistory/ShowConsultantHistory',
        'GetConsultantHistoryById': 'api/ConsultantHistory/GetConsultantHistoryById',
        'GetSpecialitiesHistory': 'api/ConsultantHistory/GetSpecialitiesHistory',
        'GetContactDetails': 'api/ConsultantHistory/GetPersonEmailPhoneHistory',
        'GetEmploymentHistory': 'api/ConsultantHistory/GetEmploymentHistory'
      },
      'Messages': {
        'GetUserMessages': 'api/Message/GetUserMessages',
        'AddUserMessage': 'api/Message/AddUserMessage',
        'UpdateUserMessage': 'api/Message/UpdateUserMessage',
        'GetUnReadMessageCount': 'api/Message/GetUnReadMessageCount',
        'SearchPerson': 'api/Message/SearchPerson'
      },
      'Permissions': {
        'GetAllPermissions': 'api/Consultant/GetAllPermissions',
        'GetPermissionsByPersonID': 'api/Consultant/GetPermissionsByPersonID',
        'UpdatePermissions': 'api/Consultant/UpdatePermissions'
      },
      'UserActivity': {
        'SearchUserActivities': 'api/Activity/SearchUserActivities'
      },
      'Invitation': {
        'SendInvitation': 'api/Email/SendInvitation',
        'ValidatePersonEmail': 'api/Consultant/ValidatePersonEmail'
      },
      'logIn': {
        'login': `api/login/getuser`,
        'loginCustom': `api/login/getuserCustom`,
        'LogOff': 'api/Login/LogOff'
      }
    }
  };

  // LoggedInUser Details
  public NetworkId = 'indrakant';
  public loginUsername = 'Indrakant Mishra';
  public UserRole = 'Admin';

  constructor() { }
}
