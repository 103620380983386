import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ApiCallService } from "src/app/services/api-call.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  BasicFormGroup: any;
  apiConfig: any;
  submitted = false;
  loader = false;
  constructor(
    private _snackBar: MatSnackBar,
    public thisDialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public ApiCallService: ApiCallService
  ) {
    this.apiConfig = this.ApiCallService.apiendPoints.logIn;
  }

  ngOnInit() {
    this.createChangePassword();
  }

  createChangePassword() {
    this.BasicFormGroup = this.fb.group(
      {
        oldpassword: ["", Validators.required],
        newpassword: ["", Validators.required],
        confirmpassword: ["", Validators.required],
      },
      { validator: this.MustMatch("newpassword", "confirmpassword") }
    );
  }
  onFormSubmit(form: FormGroup) {
    this.submitted = true;
    this.loader = true;
    if (form.valid) {
      let formdata = form.value;
      let data = {
        id: Number(localStorage.getItem("execSmartUserId")),
        createdBy: localStorage.getItem("LoggedInUser"),
        createdOn: new Date().toISOString(),
        lastUpdateBy: localStorage.getItem("LoggedInUser"),
        lastUpdateOn: new Date().toISOString(),
        isActive: true,
        status: 0,
        action: 0,
        username: localStorage.getItem("LoggedInUser"),
        password: formdata.newpassword,
        oldPassword: formdata.oldpassword,
        securityQnsId: 0,
        secutiryAns: "",
      };
      this.ApiCallService.putWithoutId(
        this.apiConfig.updatePassword,
        data
      ).subscribe(
        (res) => {
          if (res["status"] == 1) {
            this._snackBar.open("Password updated successfully", "GOT IT", {
              duration: 4000,
            });
            this.loader = false;
            this.onCloseConfirm();
          } else {
            this._snackBar.open(res["message"], "GOT IT", {
              duration: 4000,
            });
            this.loader = false;
          }
        },
        (err) => {
          console.log("Demo", err);
        }
      );
    } else {
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.BasicFormGroup.controls;
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  onCloseConfirm() {
    this.thisDialogRef.close("Confirm");
  }
  onCloseCancel() {
    this.thisDialogRef.close("Cancel");
  }
}
