import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonApiService } from "../../services/common-api.service";
import { AppSettingsService } from "../../services/app.settings.service";
import { AuthService } from "../../services/auth.service";
import { ApiCallService } from "../../services/api-call.service";
import { LOGGED_IN_USER } from "../../shared/constants";
import { ForgotPasswordComponent } from "src/app/shared/forgot-password/forgot-password.component";
import { MatDialog } from "@angular/material/dialog";
import { ChangePasswordComponent } from "src/app/shared/change-password/change-password.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  userData: any;
  emailPattern = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  hide: boolean = true;
  email: string;
  pass: string;
  BasicFormGroup: any;
  endPointConfig: any;
  showMessageStripe = false;
  loader = false;
  errorMsg = "";
  endPointConfigCustom: any;
  radioButtonItems: Array<string>;
  isSubmitted: boolean = false;
  SELECTED: string = "ESIX";
  SELECTION_TYPES: any = { ESIX: "ESIX", Consultant: "Consultant" };

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public fb: FormBuilder,
    private auth: AuthService,
    public commonApi: CommonApiService,
    public apiEndPoint: AppSettingsService,
    public apiCallService: ApiCallService
  ) {
    this.endPointConfig = this.apiCallService.apiendPoints.logIn.login;
    this.endPointConfigCustom =
      this.apiCallService.apiendPoints.logIn.loginCustom;
    this.radioButtonItems = ["sign-in as ESIX Member", "sign-in as Consultant"];
  }
  ngOnInit() {
    this.createLogin();
    const ROLE = LOGGED_IN_USER();
    if (ROLE) {
      this.router.navigate([`${ROLE}/`], { state: { showMessage: true } });
    }
  }
  createLogin() {
    this.BasicFormGroup = this.fb.group({
      isYMLogin: [true, Validators.required],
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  opendialog() {
      const dialogRef = this.dialog.open(ForgotPasswordComponent, {
        width: '500px',
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  switchType(type, ymLogin) {
    this.SELECTED = type;
    this.BasicFormGroup.patchValue({
      isYMLogin: ymLogin,
    });
  }

  onFormSubmit(form: FormGroup) {
    this.showMessageStripe = false;
    if (form.valid) {
      let data = form.value;
      this.loader = true;
      this.apiCallService.loginRequest(this.endPointConfig, data).subscribe(
        (response) => {
          let resData = response["result"];
          if (resData) {
            if (resData.roleId == 1) {
              this.auth.sendToken(
                resData.personId,
                "Admin",
                resData.execSmartUserId
              );
              this.router.navigate([`admin/`], {
                state: { showMessage: true },
              });
              // this.redirectToEsix(resData.ssoRedirectionUrl);
              this.loader = false;
            } else if (resData.roleId == 2) {
              this.auth.sendToken(
                resData.personId,
                "User",
                resData.execSmartUserId
              );
              this.router.navigate([`user/`], { state: { showMessage: true } });
              // this.redirectToEsix(resData.ssoRedirectionUrl);
              this.loader = false;
            } else {
              this.auth.sendToken(
                resData.personId,
                "Consultant",
                resData.execSmartUserId
              );
              this.router.navigate([`consultant/`], {
                state: { showMessage: true },
              });
              // this.redirectToEsix(resData.ssoRedirectionUrl);
              this.loader = false;
            }
          } else {
            this.showMessageStripe = true;
            this.errorMsg = "Invalid Credentials.";
            this.loader = false;
          }
        },
        (err) => {
          console.log("Demo", err);
        }
      );
    } else {
      this.loader = false;
      this.showMessageStripe = true;
      this.errorMsg = "Username and password can not be empty.";
    }
  }

  // onFormSubmit(form: FormGroup) {
  //   this.showMessageStripe = false;
  //   if (form.valid) {
  //     let data = form.value;
  //     data.password = data.password.replace("#", "%23");
  //     this.loader = true;
  //     if (data.isYMLogin) {
  //       this.apiCallService.loginRequest(this.endPointConfig, data).subscribe(
  //         (response) => {
  //           let resData = response["result"];
  //           if (resData) {
  //             if (resData.roleId == 1) {
  //               this.auth.sendToken(
  //                 resData.personId,
  //                 "Admin",
  //                 resData.execSmartUserId
  //               );
  //               // this.router.navigate([`admin/`], {
  //               //   state: { showMessage: true },
  //               // });
  //               this.redirectToEsix(resData.ssoRedirectionUrl);
  //               this.loader = false;
  //             } else {
  //               this.auth.sendToken(
  //                 resData.personId,
  //                 "User",
  //                 resData.execSmartUserId
  //               );
  //               // this.router.navigate([`user/`], {
  //               //   state: { showMessage: true },
  //               // });
  //               this.redirectToEsix(resData.ssoRedirectionUrl);
  //               this.loader = false;
  //             }
  //           } else {
  //             this.showMessageStripe = true;
  //             this.errorMsg = "Invalid Credentials.";
  //             this.loader = false;
  //           }
  //         },
  //         (err) => {
  //           console.log("Demo", err);
  //         }
  //       );
  //     } else if (!data.isYMLogin) {
  //       this.apiCallService
  //         .loginRequest(this.endPointConfigCustom, data)
  //         .subscribe(
  //           (response) => {
  //             let resData = response["result"];
  //             if (resData) {
  //               if (resData.roleId == 3) {
  //               this.auth.sendToken(
  //                 resData.personId,
  //                 "Consultant",
  //                 resData.execSmartUserId
  //               );
  //               this.router.navigate([`consultant/`], {
  //                 state: { showMessage: true },
  //               });
  //               //this.redirectToEsix(resData.ssoRedirectionUrl);
  //               this.loader = false;
  //             }
  //             } else {
  //               this.showMessageStripe = true;
  //               this.errorMsg = "Invalid Credentials.";
  //               this.loader = false;
  //             }
  //           },
  //           (err) => {
  //             console.log("Demo", err);
  //           }
  //         );
  //     } else {
  //       this.loader = false;
  //       this.showMessageStripe = true;
  //       this.errorMsg = "Username and password can not be empty.";
  //     }
  //   }
  // }

  redirectToEsix(redirectUrl: string) {
    window.location.assign(redirectUrl);
  }
}
